import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getBlogs } from "../../api/apiHandler";

export const getAllBlogs = createAsyncThunk(
  "blog/getAllBlogs",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getBlogs(payload);

      if (response?.code === "1") {
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

const initialState = {
  loading: false,
  data: [],
  error: null,
  page: 1,
  total: 0,
};

const blogSlice = createSlice({
  name: "blog",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getAllBlogs.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllBlogs.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload.data.data;
        state.page = payload.data.page;
        state.total = payload.data.total_records;
      })
      .addCase(getAllBlogs.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.data = [];
      });
  },
});

export default blogSlice.reducer;
