import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

const LandingFooter = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();

  return (
    <footer className="footer-main">
      <div className="footer-one">
        <div className="container">
          <div className="row">
            {location.pathname === "/" && (
              <div className="col-12">
                <div className="become-tutor-main">
                  <div className="become-tutor-sub">
                    <h2 className="become-heading">{t("becomeATutor")}</h2>
                    <p className="become-text">{t("becomeTutorSubtitle")}</p>
                    <Link
                      to="/tutor-register"
                      className="btn btn-lg btn-primary border-8px"
                    >
                      {t("applyToteach")}
                    </Link>
                    <div className="left-round-img">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/icons/left-rounded-img.svg"
                        }
                        alt="left-rounded-img.svg"
                      />
                    </div>
                    <div className="left-small-round-img">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/icons/small-round.svg"
                        }
                        alt="icons/small-round.svg"
                      />
                    </div>
                    <div className="left-box-img">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/icons/box-img.svg"
                        }
                        alt="icons/box-img.svg"
                      />
                    </div>
                    <div className="left-right-round-img">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/icons/right-round.svg"
                        }
                        alt="icons/right-round.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="col-12">
              <div className="footer-main-two">
                <div className="f-logo">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/logo/footer-logo.svg"
                    }
                    alt="logo"
                    className="logo"
                  />
                </div>
                <div className="f-text-sub">
                  <p className="f-text">{t("footerSubTitle")}</p>
                </div>
                <div className="f-menu">
                  <ul className="nav">
                    <li className="nav-item">
                      <Link className="nav-link" to="/about-us">
                        {t("about")}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/terms-condition">
                        {t("terms")}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/privacy-policy">
                        {t("privacy")}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/contact-us">
                        {t("contactUsFooter")}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to={"/cookie"}>
                        {t("cookie")}
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="f-social">
                  <ul className="list-inline">
                    <li className="list-inline-item">
                      <a
                        className="f-social-anchor px-2 text-facebook"
                        href="javascript:void(0)"
                      >
                        <i className="fab fa-fw fa-facebook-f" />
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        className="f-social-anchor px-2 text-instagram"
                        href="javascript:void(0)"
                      >
                        <i className="fab fa-fw fa-instagram"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        className="f-social-anchor px-2 text-twitter"
                        href="javascript:void(0)"
                      >
                        <i className="fab fa-fw fa-twitter"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        className="f-social-anchor px-2 text-linkedin"
                        href="javascript:void(0)"
                      >
                        <i className="fab fa-fw fa-linkedin-in"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="f-copy">
                  <p className="f-copy-text">{t("copyright")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default LandingFooter;
