import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getSemester } from "../../api/apiHandler";

export const getSemesterList = createAsyncThunk(
  "master/getSemester",
  async (payload, { rejectWithValue }) => {
    
    try {
      const response = await getSemester(payload);
      if (response?.code === "1") {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const initialState = {
  loading: false,
  data: [],
  error: null,
};

const semesterSlice = createSlice({
  name: "semester",
  initialState,

  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getSemesterList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSemesterList.fulfilled, (state, { payload }) => {
        
        state.loading = false;
        state.data = payload;
      })
      .addCase(getSemesterList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.data = [];
      });
  },
});

export default semesterSlice.reducer;
