import React, { useRef, useState, useCallback } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { Modal, Button } from "@mui/material";

const ImageCropper = ({
  imageFile,
  onCropComplete,
  open,
  handleClose,
  aspectRatio = 0,
}) => {
  const cropperRef = useRef(null);

  const cropImage = () => {
    if (cropperRef.current) {
      const cropper = cropperRef.current.cropper;
      cropper.getCroppedCanvas().toBlob((blob) => {
        if (blob) {
          const croppedImageFile = new File([blob], imageFile.name, {
            type: imageFile.type,
            lastModified: Date.now(),
          });
          onCropComplete(croppedImageFile); // Return the cropped image file
          handleClose(); // Close the modal after cropping
        }
      }, imageFile.type);
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="image-cropper-modal"
      aria-describedby="modal-for-image-cropping"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "90vw", // Adjust as needed
          maxWidth: "600px", // Maximum width
          height: "80vh", // Adjust as needed
          maxHeight: "500px", // Maximum height
          background: "#fff",
          margin: "auto",
          padding: "20px",
          overflow: "hidden",
          borderRadius: "8px",
          boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
        }}
      >
        <Cropper
          ref={cropperRef}
          src={URL.createObjectURL(imageFile)}
          style={{ height: "calc(100% - 50px)", width: "100%" }}
          aspectRatio={aspectRatio} // Free cropping
          guides={true} // Show crop guides
          autoCropArea={1} // Ensure the crop area fills the image
          viewMode={1} // Allows moving the crop box
          zoomable={true}
          scalable={true}
        />
        <Button
          variant="contained"
          onClick={cropImage}
          style={{ marginTop: "10px" }}
        >
          Crop Image
        </Button>
      </div>
    </Modal>
  );
};

export default ImageCropper;
