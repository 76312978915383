import React from "react";
import { useSelector } from "react-redux";
import StudentEditProfile from "./Student";
import TutorEditProfile from "./Tutor";

const EditProfile = () => {
  const { userInfo: userData } = useSelector((state) => state.auth);
  return userData?.user_type === "student" ? (
    <StudentEditProfile />
  ) : (
    <TutorEditProfile />
  );
};

export default EditProfile;
