import React from "react";
import { useTranslation } from "react-i18next";

import Slider from "react-slick";
const  settings = {
  dots: false,
  infinite: true,
  arrows: true,
  loop: true,
  speed: 400,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 4000,
  nextArrow: (
    <button type="button" data-role="none" class="slick-next">
      <img
        src={
          process.env.PUBLIC_URL + "/assets/images/icons/right-arrow-slider.svg"
        }
      />
    </button>
  ),
  prevArrow: (
    <button type="button" data-role="none" class="slick-prev">
      <img
        src={process.env.PUBLIC_URL + "/assets/images/icons/left-arrow.svg"}
      />{" "}
    </button>
  ),

  responsive: [
    {
      breakpoint: 1025,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 577,
      settings: {
        slidesToShow: 1,
        arrows: true,
      },
    },
  ],
};
const WhatStudentSaySection = () => {
  const { t, i18n } = useTranslation();

  return (
    <section className="what-students-section">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
            <div className="what-stds-left-main">
              <Slider className="what-stds-bg-blue" {...settings}>
                <div className="what-stds-item">
                  <div className="what-cota">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assets/images/icons/cota.svg"
                      }
                      alt="cota"
                    />
                  </div>
                  <p className="what-text">{t("reviewComments")}</p>
                  <div className="what-user-main">
                    <div className="user-img">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/icons/user-photo.svg"
                        }
                        alt="user-photo"
                      />
                    </div>
                    <div>
                      <h6 className="user-name-text">Zoe Miller</h6>
                      <p className="user-post-text">Designer</p>
                    </div>
                  </div>
                </div>
                <div className="what-stds-item">
                  <div className="what-cota">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assets/images/icons/cota.svg"
                      }
                      alt="cota"
                    />
                  </div>
                  <p className="what-text">{t("reviewComments")}</p>
                  <div className="what-user-main">
                    <div className="user-img">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/icons/user-photo.svg"
                        }
                        alt="user-photo"
                      />
                    </div>
                    <div>
                      <h6 className="user-name-text">Zoe Miller</h6>
                      <p className="user-post-text">Designer</p>
                    </div>
                  </div>
                </div>
                <div className="what-stds-item">
                  <div className="what-cota">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assets/images/icons/cota.svg"
                      }
                      alt="cota"
                    />
                  </div>
                  <p className="what-text">{t("reviewComments")}</p>
                  <div className="what-user-main">
                    <div className="user-img">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/icons/user-photo.svg"
                        }
                        alt="user-photo"
                      />
                    </div>
                    <div>
                      <h6 className="user-name-text">Zoe Miller</h6>
                      <p className="user-post-text">Designer</p>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
            <div className="what-stds-right-main">
              <div className="dotted-shap">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/icons/combined-shape.svg"
                  }
                  alt="combined-shape"
                />
              </div>
              <div className="what-teastimonial-div">
                {/* <p class="teatimonial-text">
                    Testimonials
                </p> */}
                <h2 className="what-stds-text">{t("whatStudentSays")}</h2>
                <p className="minuteness-text">{t("minutenessOfPart")}</p>
                <p className="what-looked-text">
                  {t("whatStudentSaysComments")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhatStudentSaySection;
