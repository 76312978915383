export const downloadFile = async (url, filename) => {
  try {
    const response = await fetch(url);
    const blob = await response.blob();
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {}
};

// export function getFileTypeFromS3SignedURL(url) {
//
//   try {
//     const urlObj = new URL(url);
//     const pathname = urlObj.pathname;
//     const extension = pathname
//       .substring(pathname.lastIndexOf(".") + 1)
//       .toLowerCase();

//     // Return empty string if there's no extension or if the extension includes a slash
//     return extension.includes("/") ? "" : extension;
//   } catch (error) {
//
//     return "";
//   }
// }
export function getFileTypeFromS3SignedURL(input) {
  try {
    // Check if input is a base64 data URI
    if (input.startsWith("data:")) {
      const match = input.match(/^data:(.*?);/);
      if (match && match[1]) {
        const mimeType = match[1];
        const extension = mimeType.split("/")[1].toLowerCase();
        return extension;
      } else {
        return "";
      }
    }

    // Check if input is a URL
    try {
      const urlObj = new URL(input);
      const pathname = urlObj.pathname;
      const extension = pathname
        .substring(pathname.lastIndexOf(".") + 1)
        .toLowerCase();

      // Return empty string if there's no extension or if the extension includes a slash
      return extension.includes("/") ? "" : extension;
    } catch {
      // Not a valid URL, proceed to check as a filename
    }

    // Check if input is a filename
    const filename = input;
    const extension = filename
      .substring(filename.lastIndexOf(".") + 1)
      .toLowerCase();

    // Return empty string if there's no extension or if the extension includes a slash
    return extension.includes("/") ? "" : extension;
  } catch (error) {
    return "";
  }
}
