import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllBlogs } from "../../../store/slice/blogSlice";
import moment from "moment/moment";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Blog = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const {
    blog: { data: blogData },
  } = useSelector((state) => state);
  const [firstBlog, ...restBlog] = blogData || [];

  useEffect(() => {
    dispatch(getAllBlogs({ blog_page: 4 }));
  }, [dispatch]);

  return (
    <section className="sponseer-section">
      <div className="container">
        <div className="row mb-4 mb-sm-5 ">
          <div className="col-12 text-center mx-auto">
            <h2
              className="index-heading-2"
              dangerouslySetInnerHTML={{ __html: t("latestNewsAndBlogs") }}
            ></h2>
            <p className="we-are-text">{t("blogSubTitle")}</p>
          </div>
        </div>
        <div className="row g-4 justify-content-between">
          <div className="col-md-5">
            {/* Card START */}
            <Link to={`/blog/${btoa(firstBlog?.id)}`}>
              <div className="card bg-transparent h-100">
                {/* Image */}
                <div className="flex-grow-1 d-flex align-items-center">
                  <img src={firstBlog?.image} className="card-img " alt />
                </div>
                {/* Card body */}
                <div className="card-body px-3 flex-grow-0">
                  <h4>
                    <a href="tutor-blog-details.php" className="stretched-link">
                      {firstBlog?.title}
                    </a>
                  </h4>
                  <p>{firstBlog?.sub_title}</p>
                  <div className="d-flex justify-content-between mt-2">
                    <p className="mb-0 author">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/user-circle.svg"
                        }
                      />
                      {firstBlog?.author}
                    </p>
                    <span>
                      {moment
                        .unix(firstBlog?.update_datetime)
                        .format("DD-MMM-YYYY")}
                    </span>
                  </div>
                </div>
              </div>
            </Link>
            {/* Card END */}
          </div>
          <div className="col-md-7">
            {/* Card START */}
            {restBlog?.map((item, index) => {
              return (
                <>
                  <Link to={`/blog/${btoa(item?.id)}`}>
                    <div className="card bg-transparent">
                      <div className="row align-items-center">
                        <div className="col-sm-4 col-lg-3 blog_mini">
                          <div className="blog_mini">
                            <img src={item?.image} />
                            <div className="bg_inner text-center p-3 rounded-2 mb-2 mb-sm-0">
                              <h2 className="text-white">
                                {moment
                                  .unix(item?.update_datetime)
                                  .format("DD")}
                              </h2>
                              <span className="text-white">
                                {" "}
                                {moment
                                  .unix(item?.update_datetime)
                                  .format("MMM-YYYY")}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-8 col-lg-9">
                          <div className="card-body blog_header">
                            <h5 className="card-title">
                              <a
                                href="tutor-blog-details.php"
                                className="stretched-link"
                              >
                                {item?.title}
                              </a>
                            </h5>
                            <p className="blog_desc">{item?.sub_title}</p>
                            <p className="mb-0 author">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/images/user-circle.svg"
                                }
                              />
                              {item?.author}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                  {index !== 2 && <hr className="my-3" />}
                </>
              );
            })}
          </div>
        </div>{" "}
        {/* Row END */}
        <div className="row">
          <div className="col-12">
            <div className="view-more-main">
              <Link to={"/blog"} className="apply-to-teach-anchor border-8px">
                {t("viewAll")}
                <svg
                  className="svg-20px ms-2"
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  width={512}
                  height={512}
                  x={0}
                  y={0}
                  viewBox="0 0 512 512"
                  style={{ enableBackground: "new 0 0 512 512" }}
                  xmlSpace="preserve"
                >
                  <g>
                    <path
                      d="M398.5 281h-298c-13.81 0-25-11.19-25-25s11.19-25 25-25h298c13.81 0 25 11.19 25 25s-11.19 25-25 25z"
                      fill="#ffffff"
                      opacity={1}
                      data-original="#ffffff"
                    />
                    <path
                      d="M284.01 399.25a24.96 24.96 0 0 1-18.34-8c-9.39-10.12-8.79-25.94 1.33-35.33l107.47-99.67-110.72-99.94c-10.25-9.25-11.06-25.06-1.81-35.31s25.06-11.06 35.31-1.81l131 118.25c5.21 4.7 8.2 11.37 8.25 18.39s-2.85 13.73-8 18.5L301 392.58a24.917 24.917 0 0 1-16.99 6.67z"
                      fill="#ffffff"
                      opacity={1}
                      data-original="#ffffff"
                    />
                  </g>
                </svg>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Blog;
