import React, { useEffect } from "react";
import AllRoutes from "./routes/Routes";
import { Helmet } from "react-helmet";
import { FilterProvider } from "./Common/FilterContex";
import { useLocation } from "react-router-dom";
import { useSocket } from "./Common/socketProvider";
import { useDispatch } from "react-redux";
import { appendMessage, leaveStudentGroup } from "./store/slice/chatSlices";

const App = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const socket = useSocket();

  useEffect(() => {
    document.body.classList.remove("offcanvas-open");
    document.body.style.overflow = "";
    document.body.style.paddingRight = "";
  }, [location]);

  useEffect(() => {
    if (socket) {
      console.log("check  socket Connected to server");
      socket.on("connect", (check) => {
        console.log("check  socket Connected to server===>", check);
        console.log("socket Connected to server");
      });

      socket.on("send_message", (message) => {
        console.log("check  socket send_message to server===>", message);
        console.log("socket send_message to server");
        dispatch(appendMessage(message?.data));
      });

      socket.on("leave_room", (message) => {
        console.log("check  socket leave_room to server===>", message);
        console.log("check socket leave_room to server");
        dispatch(leaveStudentGroup(message));
      });

      socket.on("disconnect", () => {
        console.log("check  socket Disconnected from server");
      });
    }
  }, [dispatch, socket]);

  return (
    <>
      <Helmet>
        <script>
          {` 
      $(document).ready(function () {
      $("select").niceSelect()
      })
      `}
        </script>
      </Helmet>
      <React.Fragment>
        <FilterProvider>
          <AllRoutes />
        </FilterProvider>
      </React.Fragment>
    </>
  );
};

export default App;
