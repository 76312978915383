import React, { lazy, Suspense, useEffect } from "react";
import { useLocation, useNavigate, useRoutes } from "react-router-dom";
import DefaultLayout from "../layouts/default/Default";
import VerticalLayout from "../layouts/dashboard/Vertical";

import {
  authRoutes,
  privateRouteList,
  publicRouteList,
  studentPrivateRouteList,
} from "./index";

import PublicLayouts from "./PublicLayout";
import PrivateLayout from "./PrivateLayout";
import AuthLayout from "../layouts/AuthLayout";
import { history } from "../Common/helpers";
import { Helmet } from "react-helmet";
import LandingTopbar from "../layouts/default/LandingTopbar";
import LandingFooter from "../layouts/default/LandingFooter";
import LandingPage from "../pages/LandingPage";
// const Logout = React.lazy(() => import("../pages/auth/Logout"));

const AllRoutes = (props) => {
  const getLayout = () => {
    return VerticalLayout;
  };

  const Layout = getLayout();

  const routes = [
    {
      path: "/",
      element: <DefaultLayout {...props} />,
      children: publicRouteList,
    },
    {
      path: "/",
      element: <PublicLayouts />,
      children: [
        {
          path: "/",
          element: <AuthLayout {...props} />,
          children: authRoutes,
        },
      ],
    },

    {
      path: "/",
      element: <PrivateLayout />,
      children: [
        {
          path: "/",
          element: <Layout {...props} />,
          children: privateRouteList,
        },
      ],
    },
  ];

  const routing = useRoutes(routes);
  history.navigate = useNavigate();
  history.location = useLocation();
  return <>{routing}</>;
};

export default AllRoutes;
