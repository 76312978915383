import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { chatList, conversationList, removeGroup } from "../../api/apiHandler";

export const getConversationList = createAsyncThunk(
  "chat/conversationList",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await conversationList(payload);

      if (response?.code === "1") {
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const getChatList = createAsyncThunk(
  "chat/chatList",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await chatList(payload);

      if (response?.code === "1") {
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const leaveGroup = createAsyncThunk(
  "chat/leaveGroup",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await removeGroup(payload);

      if (response?.code === "1") {
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

const initialState = {
  conversation_list: {
    loading: false,
    data: [],
    error: null,
    page: 1,
    total: 0,
  },
  message_list: {
    loading: false,
    data: [],
    user: {},
    error: null,
    page: 1,
    total: 0,
  },
};

const chatSlice = createSlice({
  name: "chat",
  initialState,

  reducers: {
    leaveStudentGroup: (state, action) => {
      const newMessage = action.payload;
      const { chat_id, user_id } = newMessage;

      state.conversation_list.data = state.conversation_list.data?.filter(
        (item) => item.chat_id != chat_id
      );
    },

    appendMessage: (state, action) => {
      const newMessage = action.payload;
      const { chat_id, is_read, user_id } = newMessage;

      // Check if the user exists in message_list.user and matches chat_id
      if (
        state.message_list.user &&
        state.message_list.user.chat_id == chat_id
      ) {
        // Append the new message at the beginning of the message list for the correct chat_id
        state.message_list.data.unshift(newMessage);

        // Update the conversation's unread count if the message is unread
      }

      const conversationIndex = state.conversation_list.data.findIndex(
        (conversation) => conversation.chat_id == chat_id
      );

      if (conversationIndex !== -1) {
        const conversation = state.conversation_list.data[conversationIndex];

        // Update the unread count and message details
        if (is_read == 0 && conversation.chat_id == chat_id) {
          conversation.uncon_count += 1;
        }

        conversation.message =
          newMessage.type == "photo"
            ? `📸 Image`
            : newMessage.type == "video"
            ? ` '📷 Video';`
            : newMessage.type == "doc"
            ? `📋 Doc`
            : newMessage.type == "audio"
            ? `🎙️ Audio`
            : newMessage?.message;
        conversation.insert_datetime = newMessage?.insert_datetime;
        conversation.new_user_id = user_id;
        // Remove the existing conversation from the list
        state.conversation_list.data.splice(conversationIndex, 1);

        // Insert the updated conversation at the beginning of the list
        state.conversation_list.data.unshift(conversation);
      }
    },

    readAllMessage: (state, action) => {
      const { chat_id } = action.payload;
      const conversationIndex = state.conversation_list.data.findIndex(
        (conversation) => conversation.chat_id == chat_id
      );

      if (conversationIndex !== -1) {
        const conversation = state.conversation_list.data[conversationIndex];

        // Update the unread count and message details
        conversation.uncon_count = 0;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getConversationList.pending, (state) => {
        state.conversation_list.loading = true;
      })
      .addCase(getConversationList.fulfilled, (state, { payload }) => {
        state.conversation_list.loading = false;
        if (payload.data) {
          state.conversation_list.data =
            payload.data.page === 1
              ? payload.data.data || []
              : [...state.conversation_list.data, ...payload.data.data];
          state.conversation_list.page = payload.data.page;
          state.conversation_list.total = payload.data.total_records;
        }
      })
      .addCase(getConversationList.rejected, (state, action) => {
        state.conversation_list.loading = false;
        state.conversation_list.error = action.error.message;
        state.conversation_list.data = [];
      })
      .addCase(leaveGroup.pending, (state) => {
        state.conversation_list.loading = true;
      })
      .addCase(leaveGroup.fulfilled, (state, { payload }) => {
        state.conversation_list.loading = false;
        // if (payload.data) {
        //   // state.conversation_list.data = state.conversation_list.data?.filter(
        //   //   (item) => item
        //   // );
        // //   state.conversation_list.data =
        // //     payload.data.page === 1
        // //       ? payload.data.data || []
        // //       : [...state.conversation_list.data, ...payload.data.data];
        // //   state.conversation_list.page = payload.data.page;
        // //   state.conversation_list.total = payload.data.total_records;
        // }
      })
      .addCase(leaveGroup.rejected, (state, action) => {
        state.conversation_list.loading = false;
        state.conversation_list.error = action.error.message;
        state.conversation_list.data = [];
      })
      .addCase(getChatList.pending, (state) => {
        state.message_list.loading = true;
      })
      .addCase(getChatList.fulfilled, (state, { payload }) => {
        state.message_list.loading = false;
        if (payload.data.data) {
          state.message_list.data =
            payload.data.page === 1
              ? payload.data.data
              : [...state.message_list.data, ...payload.data.data];
          state.message_list.page = payload.data.page;
          state.message_list.user = payload.data.detail;
          state.message_list.total = payload.data.total_records;
        }
      })
      .addCase(getChatList.rejected, (state, action) => {
        state.message_list.loading = false;
        state.message_list.error = action.error.message;
        state.message_list.data = [];
      });
  },
});
export const { appendMessage, readAllMessage, leaveStudentGroup } =
  chatSlice.actions;
export default chatSlice.reducer;
