import { axiosClient } from "./apiClient";

export function login(data) {
  return axiosClient.post("/api/v1/auth/signin", data);
}

export function tutorSignup(data) {
  return axiosClient.post("/api/v1/auth/tutor_signup", data);
}

export function StudentSignup(data) {
  return axiosClient.post("/api/v1/auth/student_signup", data);
}

export function getProfile(data) {
  return axiosClient.post("/api/v1/auth/getProfile", data);
}

export function logout(data) {
  return axiosClient.post("/api/v1/auth/logout", data);
}

export function deleteAccount(data) {
  return axiosClient.post("/api/v1/auth/deleteAccount", data);
}

export function changeUserPassword(data) {
  return axiosClient.post("/api/v1/auth/changePassword", data);
}

export function editStudentProfile(data) {
  return axiosClient.post("/api/v1/auth/student_edit_profile", data);
}

export function editTutorProfile(data) {
  return axiosClient.post("/api/v1/auth/tutor_edit_profile", data);
}

export function updateTutorIndividual(data) {
  return axiosClient.post("/api/v1/auth/update_data", data);
}

export function updateGoogleAccessTokenCalendar(data) {
  return axiosClient.post("/api/v1/auth/updateGoogleAccessTokenCalendar", data);
}

export function forgotPassword(data) {
  return axiosClient.post("/api/v1/auth/forgotPassword", data);
}

export function resetPassword(data) {
  return axiosClient.post("/api/v1/auth/resetPassword", data);
}

export function linkVerification(data) {
  return axiosClient.post("/api/v1/auth/verify_link", data);
}

export function getUniversity(data) {
  return axiosClient.post("/api/v1/auth/getUniversityList", data);
}

export function getSemester(data) {
  return axiosClient.post("/api/v1/auth/getSemesterList", data);
}
export function getSubject(data) {
  return axiosClient.post("/api/v1/auth/getSubjectsList", data);
}

export function getCountry(data) {
  return axiosClient.post("/api/v1/website/getCountryList", data);
}

export function getState(data) {
  return axiosClient.post("/api/v1/website/getStateList", data);
}

export function getLanguage(data) {
  return axiosClient.post("/api/v1/website/languageList", data);
}

export function addContactUs(data) {
  return axiosClient.post("/api/v1/admin/insert_contact_us", data);
}

export function getFAQ(data) {
  return axiosClient.post("/api/v1/admin/get_faqs", data);
}

export function getCMS(data) {
  return axiosClient.post("/api/v1/admin/getCmsPage", data);
}

export function getBlogs(data) {
  return axiosClient.post("/api/v1/admin/get_blogs", data);
}

export function findMyTutor(data) {
  return axiosClient.post("/api/v1/website/findMyTutor", data);
}

export function filterCategories(data) {
  return axiosClient.post("/api/v1/website/filterCategories", data);
}

export function addStudentReview(data) {
  return axiosClient.post("/api/v1/student/addReviewRating", data);
}

export function getTutorRatingList(data) {
  return axiosClient.post("/api/v1/website/getRatingList", data);
}

export function addUpdatefavorite(data) {
  return axiosClient.post("/api/v1/student/addUpdatefavorite", data);
}

export function getFavouriteTutorList(data) {
  return axiosClient.post("/api/v1/student/GetFavouriteList", data);
}

export function myStudent(data) {
  return axiosClient.post("/api/v1/tutor/myStudent", data);
}

export function setTutorAvailability(data) {
  return axiosClient.post("/api/v1/tutor/set_availability_demo", data);
}

export function setTutorCustomAvailability(data) {
  return axiosClient.post("/api/v1/tutor/set_custom_availability_demo", data);
}

export function getMonthlySlots(data) {
  return axiosClient.post(
    "/api/v1/tutor/get_availability_month_slot_demo",
    data
  );
}

export function getBookingLists(data) {
  return axiosClient.post("/api/v1/student/myBookingLists", data);
}

export function changeBookingStatus(data) {
  return axiosClient.post("/api/v1/student/serviceAction", data);
}

export function resecheduleIndividual(data) {
  return axiosClient.post("/api/v1/tutor/resecheduleIndividual", data);
}

export function getCustomSlots(data) {
  return axiosClient.post(
    "/api/v1/tutor/get_custom_available_month_slot_demo",
    data
  );
}

export function getCourseBookedSlots(data) {
  return axiosClient.post("/api/v1/tutor/get_booking_course_slots", data);
}

export function getAllTutorSlots(data) {
  return axiosClient.post("/api/v1/tutor/get_all_sloats_details", data);
}

export function getAllSettings(data) {
  return axiosClient.post("/api/v1/admin/GetSettings", data);
}

export function checkStudentDiscount(data) {
  return axiosClient.post("/api/v1/student/checkDiscount", data);
}

export function guestSignup(data) {
  return axiosClient.post("/api/v1/student/guestSignup", data);
}

export function addBooking(data) {
  return axiosClient.post("/api/v1/student/addBooking", data);
}

export function addCourses(data) {
  return axiosClient.post("/api/v1/tutor/addCourses", data);
}

export function getTutorCourses(data) {
  return axiosClient.post("/api/v1/tutor/GetCourses", data);
}

export function getWebCourses(data) {
  return axiosClient.post("/api/v1/website/GetWebCourses", data);
}

export function addCourseBookingSloats(data) {
  return axiosClient.post("/api/v1/student/addCourseBookingSloats", data);
}

export function deleteCourses(data) {
  return axiosClient.post("/api/v1/tutor/deleteCourses", data);
}

export function getCourseBookingSloats(data) {
  return axiosClient.post("/api/v1/student/coursesSloats", data);
}

export function deleteCourseSloats(data) {
  return axiosClient.post("/api/v1/tutor/deleteCourseSloats", data);
}

export function resecheduleCourses(data) {
  return axiosClient.post("/api/v1/tutor/resecheduleCourses", data);
}

export function courseBookingList(data) {
  return axiosClient.post("/api/v1/tutor/myCourseBookingLists", data);
}

export function acceptRejectcancelRequest(data) {
  return axiosClient.post("/api/v1/tutor/AcceptRejectcancelRequest", data);
}

export function liveCourseBookingLists(data) {
  return axiosClient.post("/api/v1/student/liveCourseBookingLists", data);
}

export function createStripeAccount(data) {
  return axiosClient.post("/api/v1/payments/createAccount", data);
}

export function expressAccountCallback(data) {
  return axiosClient.post("/api/v1/payments/expressAccountCallback", data);
}

export function checkPaymentConfigration(data) {
  return axiosClient.post("/api/v1/student/checkPaymentConfigration", data);
}

export function getAllNotificationList(data) {
  return axiosClient.post("/api/v1/admin/notificationList", data);
}

export function readNotification(data) {
  return axiosClient.post("/api/v1/admin/readNotification", data);
}

export function studentDashboard(data) {
  return axiosClient.post("/api/v1/student/studentDashboard", data);
}

export function tutorDashboard(data) {
  return axiosClient.post("/api/v1/tutor/tutorDashboard", data);
}

export function tutorCalendarDashboard(data) {
  return axiosClient.post("/api/v1/tutor/tutorCalendarDashboard", data);
}

export function tutorEarningDashboard(data) {
  return axiosClient.post("/api/v1/tutor/tutorEarningDashboard", data);
}

// =====================================CHAT==============================

export function conversationList(data) {
  return axiosClient.post("/api/v1/chat/recent_chat_list", data);
}

export function chatList(data) {
  return axiosClient.post("/api/v1/chat/personal_chat_list", data);
}

export function removeGroup(data) {
  return axiosClient.post("/api/v1/admin/addRemoveGroupUsers", data);
}

export function microsoftAuthentication(data) {
  return axiosClient.post("/api/v1/auth/team_auth", data);
}

export function microsoftToken(data) {
  return axiosClient.post("/api/v1/auth/team_token", data);
}

export function microsoftRefreshTokenToAccessToken(data) {
  return axiosClient.post("/api/v1/auth/updateTeamAccessTokenCalendar", data);
}

export function updateShareCount(data) {
  return axiosClient.post("/api/v1/website/update_share", data);
}

