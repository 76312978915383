import React from "react";
import { useFormikContext, ErrorMessage } from "formik";

const FormikErrorMessage = ({ name, ...props }) => {
  const { submitCount } = useFormikContext();

  return submitCount > 0 ? <ErrorMessage name={name} {...props} /> : null;
};

export default FormikErrorMessage;
