import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  checkStudentDiscount,
  filterCategories,
  getAllSettings,
  getCountry,
  getLanguage,
  getState,
  studentDashboard,
  tutorCalendarDashboard,
  tutorDashboard,
  tutorEarningDashboard,
} from "../../api/apiHandler";

const initialState = {
  country: { loading: false, data: [], error: null, success: false },
  state: { loading: false, data: [], error: null, success: false },
  language: { loading: false, data: [], error: null, success: false },
  settings: { loading: false, data: [], error: null, success: false },
  discount: { loading: false, data: [], error: null, success: false },
  filter_category: { loading: false, data: [], error: null, success: false },
  student_dashboard: { loading: false, data: [], error: null, success: false },
  tutor_dashboard: { loading: false, data: [], error: null, success: false },
  tutor_calender_dashboard: {
    loading: false,
    data: [],
    error: null,
    success: false,
  },
  tutor_earning_dashboard: {
    loading: false,
    data: [],
    error: null,
    success: false,
  },
};

export const getCountryList = createAsyncThunk(
  "master/getCountry",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getCountry(payload);
      if (response?.code === "1") {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getStateList = createAsyncThunk(
  "master/getState",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getState(payload);
      if (response?.code === "1") {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getLanguageList = createAsyncThunk(
  "master/getLanguage",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getLanguage(payload);
      if (response?.code === "1") {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const checkDiscount = createAsyncThunk(
  "master/checkDiscount",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await checkStudentDiscount(payload);
      if (response?.code === "1") {
        return response.data;
      } else {
        return rejectWithValue(response);
      }
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const getSettings = createAsyncThunk(
  "master/getSettings",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getAllSettings(payload);
      if (response?.code === "1") {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getCategoriesFilter = createAsyncThunk(
  "master/getFilterCategory",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await filterCategories(payload);
      if (response?.code === "1") {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getStudentDashboard = createAsyncThunk(
  "master/getStudentDashboard",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await studentDashboard(payload);
      if (response?.code === "1") {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getTutorDashboard = createAsyncThunk(
  "master/getTutorDashboard",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await tutorDashboard(payload);
      if (response?.code === "1") {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getTutorCalenderDashboard = createAsyncThunk(
  "master/getTutorCalenderDashboard",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await tutorCalendarDashboard(payload);

      if (response?.code === "1") {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getTutorEarningDashboard = createAsyncThunk(
  "master/getTutorEarningDashboard",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await tutorEarningDashboard(payload);

      if (response?.code === "1") {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const masterSlice = createSlice({
  name: "master",
  initialState,

  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getCountryList.pending, (state) => {
        state.country.loading = true;
      })
      .addCase(getCountryList.fulfilled, (state, { payload }) => {
        state.country.loading = false;
        state.country.success = true;
        state.country.data = payload;
      })
      .addCase(getCountryList.rejected, (state, action) => {
        state.country.loading = false;
        state.country.error = action.error.message;
      })
      .addCase(getLanguageList.pending, (state) => {
        state.language.loading = true;
      })
      .addCase(getLanguageList.fulfilled, (state, { payload }) => {
        state.language.loading = false;
        state.language.success = true;
        state.language.data = payload;
      })
      .addCase(getLanguageList.rejected, (state, action) => {
        state.language.loading = false;
        state.language.error = action.error.message;
      })
      .addCase(getSettings.pending, (state) => {
        state.settings.loading = true;
      })
      .addCase(getSettings.fulfilled, (state, { payload }) => {
        state.settings.loading = false;
        state.settings.success = true;
        state.settings.data = payload.data;
      })
      .addCase(getSettings.rejected, (state, action) => {
        state.settings.loading = false;
        state.settings.error = action.error.message;
      })
      .addCase(getCategoriesFilter.pending, (state) => {
        state.filter_category.loading = true;
      })
      .addCase(getCategoriesFilter.fulfilled, (state, { payload }) => {
        state.filter_category.loading = false;
        state.filter_category.success = true;
        state.filter_category.data = payload.data;
      })
      .addCase(getCategoriesFilter.rejected, (state, action) => {
        state.filter_category.loading = false;
        state.filter_category.error = action.error.message;
      })
      .addCase(checkDiscount.pending, (state) => {
        state.discount.loading = true;
      })
      .addCase(checkDiscount.fulfilled, (state, { payload }) => {
        state.discount.loading = false;
        state.discount.success = true;
        state.discount.data = payload;
      })
      .addCase(checkDiscount.rejected, (state, action) => {
        state.discount.loading = false;
        state.discount.error = action.error.message;
      })
      .addCase(getStateList.pending, (state) => {
        state.state.loading = true;
      })
      .addCase(getStateList.fulfilled, (state, { payload }) => {
        state.state.loading = false;
        state.state.success = true;
        state.state.data = payload;
      })
      .addCase(getStateList.rejected, (state, action) => {
        state.state.loading = false;
        state.state.error = action.error.message;
      })
      .addCase(getStudentDashboard.pending, (state) => {
        state.student_dashboard.loading = true;
      })
      .addCase(getStudentDashboard.fulfilled, (state, { payload }) => {
        state.student_dashboard.loading = false;
        state.student_dashboard.success = true;
        state.student_dashboard.data = payload;
      })
      .addCase(getStudentDashboard.rejected, (state, action) => {
        state.student_dashboard.loading = false;
        state.student_dashboard.error = action.error.message;
      })
      .addCase(getTutorDashboard.pending, (state) => {
        state.tutor_dashboard.loading = true;
      })
      .addCase(getTutorDashboard.fulfilled, (state, { payload }) => {
        state.tutor_dashboard.loading = false;
        state.tutor_dashboard.success = true;
        state.tutor_dashboard.data = payload;
      })
      .addCase(getTutorDashboard.rejected, (state, action) => {
        state.tutor_dashboard.loading = false;
        state.tutor_dashboard.error = action.error.message;
      })
      .addCase(getTutorCalenderDashboard.pending, (state) => {
        state.tutor_calender_dashboard.loading = true;
      })
      .addCase(getTutorCalenderDashboard.fulfilled, (state, { payload }) => {
        state.tutor_calender_dashboard.loading = false;
        state.tutor_calender_dashboard.success = true;
        state.tutor_calender_dashboard.data = payload;
      })
      .addCase(getTutorCalenderDashboard.rejected, (state, action) => {
        state.tutor_calender_dashboard.data = [];
        state.tutor_calender_dashboard.loading = false;
        state.tutor_calender_dashboard.error = action.error.message;
      })
      .addCase(getTutorEarningDashboard.pending, (state) => {
        state.tutor_earning_dashboard.loading = true;
      })
      .addCase(getTutorEarningDashboard.fulfilled, (state, { payload }) => {
        state.tutor_earning_dashboard.loading = false;
        state.tutor_earning_dashboard.success = true;
        state.tutor_earning_dashboard.data = payload;
      })
      .addCase(getTutorEarningDashboard.rejected, (state, action) => {
        state.tutor_earning_dashboard.data = [];
        state.tutor_earning_dashboard.loading = false;
        state.tutor_earning_dashboard.error = action.error.message;
      });
  },
});

export default masterSlice.reducer;
