import React from "react";
import { useTranslation } from "react-i18next";
import OwlCarousel from "react-owl-carousel";

const setting = {
  loop: true,
  margin: 20,
  nav: false,
  dots: true,
  autoplay: true,
  autoplayTimeout: 5000, // Adjust autoplay speed (in milliseconds)
  responsive: {
    0: {
      items: 2,
    },
    600: {
      items: 3,
    },
    1000: {
      items: 5,
    },
  },
};

const OurSponsor = () => {
  const { t, i18n } = useTranslation();

  return (
    <section className="sponseer-section">
      <div className="container">
        <div className="row mb-4 mb-sm-5 ">
          <div className="col-12 text-center mx-auto">
            <h2
              className="index-heading-2"
              dangerouslySetInnerHTML={{ __html: t("ourSponsers") }}
            ></h2>
            <p className="we-are-text">{t("ourSponsersSubTitle")}</p>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-12">
            {/* Slider START */}

            <OwlCarousel className="owl-theme" id="sponser" {...setting}>
              <div className="item">
                <div className="logo_sponsered">
                  <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSIc4XjRkPWvVd7-faeg0tKo-dIrux7Zenl75GDcnl04w&s" />
                </div>
              </div>
              <div className="item">
                <div className="logo_sponsered">
                  <img src="https://i0.wp.com/goridemoto.com/wp-content/uploads/2022/09/logo4.png?ssl=1" />
                </div>
              </div>
              <div className="item">
                <div className="logo_sponsered">
                  <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSH4DS6rNOBEHo2QYIft06NkWS2NeYC8XLAI3Vp1gA5b3C3Eqx220kAssBV9Kn05WRrlGg&usqp=CAU" />
                </div>
              </div>
              <div className="item">
                <div className="logo_sponsered">
                  <img src="https://i0.wp.com/gettuff.org/wp-content/uploads/2022/12/logo04-1.png?ssl=1" />
                </div>
              </div>
              <div className="item">
                <div className="logo_sponsered">
                  <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSIc4XjRkPWvVd7-faeg0tKo-dIrux7Zenl75GDcnl04w&s" />
                </div>
              </div>
              <div className="item">
                <div className="logo_sponsered">
                  <img src="https://i0.wp.com/gettuff.org/wp-content/uploads/2022/12/logo04-1.png?ssl=1" />
                </div>
              </div>
              <div className="item">
                <div className="logo_sponsered">
                  <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSTjqplEjwCHZbGTiIHJWcVND_CTQY5CNI8r0DdpNNsDEcAXJ1e89jsGAJDogLgEaMF-mg&usqp=CAU" />
                </div>
              </div>
              <div className="item">
                <div className="logo_sponsered">
                  <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSIc4XjRkPWvVd7-faeg0tKo-dIrux7Zenl75GDcnl04w&s" />
                </div>
              </div>
            </OwlCarousel>

            {/* <div className="owl-carousel owl-theme" id="sponser"></div> */}
            {/* Slider END */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurSponsor;
