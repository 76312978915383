import React from "react";
import Loading from "react-loading";

const NewLoader = () => {
  return (
    <Loading
      type={"spinningBubbles"}
      color={"#066ac9"}
      height={"10%"}
      width={"10%"}
    />
  );
};

export default NewLoader;
