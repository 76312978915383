import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  StudentSignup,
  login,
  tutorSignup,
  logout,
  editStudentProfile,
  editTutorProfile,
  changeUserPassword,
  updateTutorIndividual,
  deleteAccount,
  updateGoogleAccessTokenCalendar,
  getProfile,
} from "../../api/apiHandler";
import {
  getToken,
  getUserDataFromLocal,
  removeUserDataFromLocal,
  saveUserDataToLocal,
} from "../../Common/LocalStorageService";
import { history } from "../../Common/helpers";

export const loginUser = createAsyncThunk(
  "auth/loginUser",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await login(payload);

      if (response?.code === "1") {
        saveUserDataToLocal(response.data);
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const registerTutor = createAsyncThunk(
  "auth/registerTutor",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await tutorSignup(payload);

      if (response?.code === "1") {
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const registerStudent = createAsyncThunk(
  "auth/registerStudent",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await StudentSignup(payload);

      if (response?.code === "1") {
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const editStudent = createAsyncThunk(
  "auth/editStudent",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await editStudentProfile(payload);

      if (response?.code === "1") {
        saveUserDataToLocal(response.data);
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const editTutor = createAsyncThunk(
  "auth/editTutor",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await editTutorProfile(payload);

      if (response?.code === "1") {
        saveUserDataToLocal(response.data);
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const getUserProfile = createAsyncThunk(
  "auth/getProfile",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getProfile(payload);

      if (response?.code === "1") {
        saveUserDataToLocal(response.data);
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const updateTutorIndividualField = createAsyncThunk(
  "auth/updateIndividualField",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await updateTutorIndividual(payload);

      if (response?.code === "1") {
        saveUserDataToLocal(response.data);
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const updateGoogleAccess = createAsyncThunk(
  "auth/updateGoogleAccess",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await updateGoogleAccessTokenCalendar(payload);

      if (response?.code === "1") {
        saveUserDataToLocal(response.data);
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const logoutUser = createAsyncThunk(
  "auth/logoutUser",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await logout(payload);

      if (response?.code === "1") {
        removeUserDataFromLocal();

        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const changePassword = createAsyncThunk(
  "auth/changePassword",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await changeUserPassword(payload); 

      if (response?.code === "1") {
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const deleteUserAccount = createAsyncThunk(
  "auth/deleteAccount",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await deleteAccount(payload);

      if (response?.code === "1") {
        removeUserDataFromLocal();
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

const userToken = getToken();

const initialState = {
  loading: false,
  userInfo: getUserDataFromLocal(),
  userToken,
  error: null,
  success: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,

  reducers: {
    logoutAction: (state) => {
      localStorage.clear();

      history.navigate("/");
      state.userInfo = null; // deletes token from storage
      state.userToken = null;
    },
    updateUser: (state, action) => {
      state.userInfo = action.payload;
      saveUserDataToLocal(action.payload);
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(loginUser.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.userInfo = payload.data;
        state.userToken = payload.data.token;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getUserProfile.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserProfile.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.userInfo = payload.data;
        state.userToken = payload.data.token;
      })
      .addCase(getUserProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(registerTutor.pending, (state) => {
        state.loading = true;
      })
      .addCase(registerTutor.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(registerTutor.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(registerStudent.pending, (state) => {
        state.loading = true;
      })
      .addCase(registerStudent.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(registerStudent.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(editStudent.pending, (state) => {
        state.loading = true;
      })
      .addCase(editStudent.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.userInfo = payload.data;
        state.userToken = payload.data.token;
      })
      .addCase(editStudent.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(editTutor.pending, (state) => {
        state.loading = true;
      })
      .addCase(editTutor.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.userInfo = payload.data;
        state.userToken = payload.data.token;
      })
      .addCase(editTutor.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateTutorIndividualField.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateTutorIndividualField.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.userInfo = payload.data;
        state.userToken = payload.data.token;
      })
      .addCase(updateTutorIndividualField.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateGoogleAccess.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateGoogleAccess.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.userInfo = payload.data;
        state.userToken = payload.data.token;
      })
      .addCase(updateGoogleAccess.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(logoutUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(logoutUser.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.userInfo = null;
        state.userToken = null;
        state.error = null;
      })
      .addCase(logoutUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteUserAccount.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteUserAccount.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.userInfo = null;
        state.userToken = null;
        state.error = null;
      })
      .addCase(deleteUserAccount.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(changePassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(changePassword.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { logoutAction, updateUser } = authSlice.actions;

export default authSlice.reducer;
