import { useSelector } from "react-redux";

const { useState, useEffect } = require("react");
const { useNavigate, Outlet } = require("react-router-dom");

const PublicLayouts = () => {
  const navigate = useNavigate();
  const [isRender, setIsRender] = useState(false);

  const isLoggedIn = useSelector((state) => state.auth.userToken);

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/dashboard");
    } else {
      setIsRender(true);
    }
  }, [isLoggedIn, navigate]);

  if (!isRender) {
    return null;
  }

  return <Outlet />;
};

export default PublicLayouts;
