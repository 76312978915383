import React, { lazy } from "react";
import EditProfile from "../pages/EditProfile";

// const Logout = lazy(() => import("../pages/auth/Logout"));
const Login = lazy(() => import("../pages/auth/Login"));
const LandingPage = lazy(() => import("../pages/LandingPage"));
const AboutUsPage = lazy(() => import("../pages/AboutUs"));
const CMSPages = lazy(() => import("../pages/CMSPages"));
const BlogPages = lazy(() => import("../pages/Blog"));
const BlogDetails = lazy(() => import("../pages/Blog/BlogDetails"));
const ContactUsPage = lazy(() => import("../pages/ContactUs"));
const FindMyTutor = lazy(() => import("../pages/FindMyTutor"));
const TutorDetails = lazy(() => import("../pages/TutorDetails"));
const TutorReview = lazy(() => import("../pages/TutorDetails/TutorReview"));
const Courses = lazy(() => import("../pages/courses"));
const CoursesDetail = lazy(() => import("../pages/CourseDetail"));
const CoursesBooking = lazy(() =>
  import("../pages/CourseDetail/CourseBooking")
);
const TutorRegister = lazy(() => import("../pages/auth/TutorSignup"));
const StudentRegister = lazy(() => import("../pages/auth/StudentSignup"));
const TutorRegisterVerification = lazy(() =>
  import("../pages/auth/TutorSignupVerification")
);
const ChangePassword = lazy(() => import("../pages/auth/ChangePassword"));
const Resetpassword = lazy(() => import("../pages/auth/Resetpasswork"));
const Dashboard = lazy(() => import("../pages/dashboard"));
const Messages = lazy(() => import("../pages/Messages"));
const ProfilePage = lazy(() => import("../pages/Profile"));
const FavouriteTutor = lazy(() => import("../pages/FavouriteTutor"));
const Schedule = lazy(() => import("../pages/Schedule"));
const MicrosoftAuthenticationSuccess = lazy(() =>
  import("../pages/Schedule/components/MicrosoftAuthenticationSuccess")
);
const CourseCalendar = lazy(() => import("../pages/Schedule/CourseCalendar"));
const TutorBookingPage = lazy(() => import("../pages/TutorBoooking"));
const StudentCourseBooking = lazy(() =>
  import("../pages/StudentCourseBooking")
);
const MyTutoringPage = lazy(() => import("../pages/MyTutoring"));
const GroupCourse = lazy(() => import("../pages/GroupCourse"));
const AddGroupCourse = lazy(() =>
  import("../pages/GroupCourse/AddGroupCourse")
);
const CourseBookingDetails = lazy(() =>
  import("../pages/GroupCourse/CourseBookingDetails")
);
const CourseSlotDetails = lazy(() =>
  import("../pages/GroupCourse/CourseSlotList")
);

const StudentPage = lazy(() => import("../pages/Students"));
const StudentCourseSlotList = lazy(() =>
  import("../pages/StudentCourseBooking/components/slotList")
);
const BookingPaymentStatus = lazy(() => import("../pages/BookingStatus"));

const PaymentSuccessPage = lazy(() => import("../pages/PaymentSuccess"));
const PaymentFailedPage = lazy(() => import("../pages/PaymentFailed"));
const NotificationPage = lazy(() => import("../pages/Notification"));

const publicRouteList = [
  {
    path: "/",
    name: "Landing Page",
    element: <LandingPage />,
  },
  {
    path: "/find-a-tutor",
    name: "Find My Tutor",
    element: <FindMyTutor />,
  },
  {
    path: "/tutor-details/:id",
    name: "Tutor Details",
    element: <TutorDetails />,
  },
  {
    path: "/tutor-review/:id",
    name: "Tutor Review",
    element: <TutorReview />,
  },
  {
    path: "/courses",
    name: "Courses",
    element: <Courses />,
  },
  {
    path: "/course-detail/:id",
    name: "Courses",
    element: <CoursesDetail />,
  },
  {
    path: "/course-detail/:id/payment",
    name: "Courses Payment",
    element: <CoursesBooking />,
  },
  {
    path: "/about-us",
    name: "About Us",
    element: <AboutUsPage />,
  },
  {
    path: "/terms-condition",
    name: "Terms Condition",
    element: <CMSPages page_name="terms&condition" />,
  },
  {
    path: "/privacy-policy",
    name: "Privacy Policy",
    element: <CMSPages page_name="privacy_policy" />,
  },
  {
    path: "/cookie",
    name: "Terms Condition",
    element: <CMSPages page_name="cookies" />,
  },
  {
    path: "/contact-us",
    name: "Contact Us",
    element: <ContactUsPage />,
  },
  {
    path: "/blog",
    name: "Blog",
    element: <BlogPages />,
  },
  {
    path: "/blog/:id",
    name: "Blog",
    element: <BlogDetails />,
  },
  {
    path: "/:type/:id/booking-status",
    name: "Blog",
    element: <BookingPaymentStatus />,
  },
];

const authRoutes = [
  {
    path: "/login",
    name: "Login",
    element: <Login />,
  },
  {
    path: "/tutor-register",
    name: "Tutor Register",
    element: <TutorRegister />,
  },
  {
    path: "/student-register",
    name: "Student Register",
    element: <StudentRegister />,
  },
  {
    path: "/verification",
    name: "Verification",
    element: <TutorRegisterVerification />,
  },
  {
    path: "/reset-password",
    name: "Reset Password",
    element: <Resetpassword />,
  },
];

const privateRouteList = [
  {
    path: "/dashboard",
    name: "Dashboards",
    element: <Dashboard />,
  },
  {
    path: "/change-password",
    name: "Change Password",
    element: <ChangePassword />,
  },
  {
    path: "/messages",
    name: "Messages",
    element: <Messages />,
  },
  {
    path: "/messages/:chatType",
    // path: "/messages/:chatType/:chatId",
    name: "Messages",
    element: <Messages />,
  },
  {
    path: "/profile",
    name: "Profile",
    element: <ProfilePage />,
  },
  {
    path: "/edit-profile",
    name: "Edit Profile",
    element: <EditProfile />,
  },
  {
    path: "/favourite-tutors",
    name: "favourite-tutors",
    element: <FavouriteTutor />,
  },
  {
    path: "/schedule",
    name: "Schedule",
    element: <Schedule />,
  },
  {
    path: "/schedule/microsoft",
    name: "Schedule",
    element: <MicrosoftAuthenticationSuccess />,
  },
  {
    path: "/schedule/course-scheduled",
    name: "Schedule",
    element: <CourseCalendar />,
  },
  {
    path: "/tutor-booking",
    name: "Tutor Bookings",
    element: <TutorBookingPage />,
  },
  {
    path: "/course-booking",
    name: "course Bookings",
    element: <StudentCourseBooking />,
  },
  {
    path: "/course-booking/slot-details/:id",
    name: "course Bookings",
    element: <StudentCourseSlotList />,
  },
  {
    path: "/my-tutoring",
    name: "My Tutoring",
    element: <MyTutoringPage />,
  },
  {
    path: "/group-course",
    name: "Group Course",
    element: <GroupCourse />,
  },
  {
    path: "/group-course/add",
    name: "Add Group Course",
    element: <AddGroupCourse />,
  },
  {
    path: "/group-course/booking-details/:id",
    name: "Course Booking Details",
    element: <CourseBookingDetails />,
  },
  {
    path: "/group-course/slot-details/:id",
    name: "Course slot Details",
    element: <CourseSlotDetails />,
  },
  {
    path: "/students",
    name: "Students",
    element: <StudentPage />,
  },
  {
    path: "/bank-success/:userId/:userType/:id",
    name: "Bank Success",
    element: <PaymentSuccessPage />,
  },
  {
    path: "/bank-failed/:userId/:userType",
    name: "Bank Failed",
    element: <PaymentFailedPage />,
  },
  {
    path: "/notification",
    name: "Notification",
    element: <NotificationPage />,
  },
];

export { publicRouteList, privateRouteList, authRoutes };
