export function loginRedirectCall() {
  // let path= window.location.protocol+"//"+window.location.host+"/admin"
  //    window.open(path,'mywindow').focus()
  //
  // window.location.pathname = "/";
}

export function logOutRedirectCall() {
  //
  //
  localStorage.clear();
  // store.dispatch(logoutAction())
  // history.navigate("/");
  window.location.reload();
  // loginRedirectCall();
}
