import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getFindMyTutorList } from "../../../store/slice/tutorSlice";
import { useTranslation } from "react-i18next";
import NewLoader from "../../../components/Loader/NewLoader";

const ExperiencedTutorSection = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    university: { data: universities, selected_university },
    tutor: { data: tutorData, total, loading },
  } = useSelector((state) => state);

  const initialFilter = useMemo(() => {
    return {
      page: 1,
      find_by_tutor_name: "",
      find_by_university: selected_university.id || universities?.[0]?.id,
      find_by_semester: "",
      find_by_language: "",
      order_by_experiance: "1",
    };
  }, [selected_university.id, universities]);

  useEffect(() => {
    dispatch(getFindMyTutorList(initialFilter));
  }, [dispatch, initialFilter]);

  return (
    <section className="our-experienced-tutors-section">
      <div className="container">
        {/* Title */}
        <div className="row">
          <div className="col-lg-8">
            <h2
              className="index-heading-2"
              dangerouslySetInnerHTML={{ __html: t("ourExpTutor") }}
            ></h2>
            <p className="text-reach">{t("reachOutMostOurExp")}</p>
          </div>
        </div>
        <div className="row">
          {loading ? (
            <div className="d-flex justify-content-center align-items-center">
              <NewLoader />
            </div>
          ) : total > 0 ? (
            tutorData?.map((tutor) => (
              <TutorCard key={tutor?.id} tutor={tutor} />
            ))
          ) : (
            <div
              style={{
                height: "300px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {t("noDataFound")}
            </div>
          )}
        </div>
        {/* Button */}
        {total > 0 && (
          <div className="view-more-main">
            <Link
              to={"/find-a-tutor"}
              className="apply-to-teach-anchor border-8px"
            >
              {t("viewAll")}
              <svg
                className="svg-20px ms-2"
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width={512}
                height={512}
                x={0}
                y={0}
                viewBox="0 0 512 512"
                style={{ enableBackground: "new 0 0 512 512" }}
                xmlSpace="preserve"
              >
                <g>
                  <path
                    d="M398.5 281h-298c-13.81 0-25-11.19-25-25s11.19-25 25-25h298c13.81 0 25 11.19 25 25s-11.19 25-25 25z"
                    fill="#ffffff"
                    opacity={1}
                    data-original="#ffffff"
                  />
                  <path
                    d="M284.01 399.25a24.96 24.96 0 0 1-18.34-8c-9.39-10.12-8.79-25.94 1.33-35.33l107.47-99.67-110.72-99.94c-10.25-9.25-11.06-25.06-1.81-35.31s25.06-11.06 35.31-1.81l131 118.25c5.21 4.7 8.2 11.37 8.25 18.39s-2.85 13.73-8 18.5L301 392.58a24.917 24.917 0 0 1-16.99 6.67z"
                    fill="#ffffff"
                    opacity={1}
                    data-original="#ffffff"
                  />
                </g>
              </svg>
            </Link>
          </div>
        )}
      </div>
    </section>
  );
};

const TutorCard = ({ tutor }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
      <div
        className="card p-2 h-100"
        onClick={() => {
          navigate(`/tutor-details/${btoa(tutor?.id)}`);
        }}
      >
        <div className="rounded-top overflow-hidden">
          <a>
            <div className="card-overlay-hover">
              <img
                src={tutor?.profile_image}
                className="card-img-top"
                alt="course image"
              />
            </div>
          </a>
        </div>
        <div className="card-body px-2 d-flex flex-column">
          <a className="d-flex flex-column flex-grow-1">
            <div className="user-name-post">
              <h6 className="card-title">{tutor?.username}</h6>
              <h5 className="text-currancy text-blue-page mb-0">
                CHF {tutor?.price}
              </h5>
            </div>
            <div className="">
              <ul className="list-inline hstack gap-2 mb-0 d-flex justify-content-between">
                <li className="list-inline-item d-flex justify-content-center align-items-center">
                  <a className="rate-anchor">
                    <div className="icon-md bg-orange bg-opacity-10 text-orange rounded-circle">
                      <i className="fas fa-user-graduate" />
                    </div>
                    <span className="number-of-std-rating">
                      {tutor?.taught_students} {t("student")}
                    </span>
                  </a>
                </li>
                <li className="list-inline-item d-flex justify-content-center align-items-center">
                  <a className="rate-anchor">
                    <div className="icon-md bg-warning bg-opacity-15 text-warning rounded-circle">
                      <i className="fas fa-star" />
                    </div>
                    {/* <RatingStars rating={tutor?.rating || 0} /> */}
                    <span className="number-of-std-rating">
                      {tutor?.rating}
                    </span>
                  </a>
                </li>
              </ul>
            </div>
            <hr className="my-2" />
            <div className="flex-grow-1">
              {tutor?.user_subject?.main?.slice(0, 3)?.map((subject, index) => (
                <div
                  className="tutor-find-text-main "
                  style={{ marginTop: "0px" ,alignItems:"start"}}
                  key={index}
                >
                  <p className="tutor-find-text" style={{ maxWidth: "65%" }}>
                    {subject.orignal_selected_subject}
                  </p>
                  <p className="tutor-find-text" style={{ minWidth: "70px" }}>
                    {t("grade")}: {parseFloat(subject.grade).toFixed(1)}
                  </p>
                </div>
              ))}
            </div>
            {/* {tutor?.user_subject?.main?.length > 2 && (
              <div className="text-end d-flex justify-content-end">
                <div className="badge bg-primary fw-bold">
                  + {t("viewMore")}
                </div>
              </div>
            )} */}
            <hr className="my-2" />
            <div className="tutor-find-text-main m-0">
              <p className="tutor-find-text">{t("languageCap")}</p>
              <ul className="tutor-find-la-ul">
                {tutor?.user_language?.language?.map((language, index) => (
                  <>
                    <li className="tutor-find-la-li d-flex" key={index}>
                      {language.language === "French" ? (
                        <>
                          <p className="d-flex me-1">FR</p>

                          <img
                            className="me-1"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/flag/fr.svg"
                            }
                          />
                        </>
                      ) : language.language === "German" ? (
                        <>
                          <p className="d-flex me-1">DE</p>

                          <img
                            className="me-1"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/flag/de.svg"
                            }
                          />
                        </>
                      ) : (
                        <>
                          <p className="d-flex me-1">EN</p>

                          <img
                            className="me-1"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/flag/en.svg"
                            }
                          />
                        </>
                      )}
                      {tutor?.user_language?.language?.length - 1 != index &&
                        ", "}
                    </li>
                  </>
                ))}
              </ul>
            </div>
            <hr className="mt-2" />
            <button>See More</button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default ExperiencedTutorSection;
