import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getUniversity } from "../../api/apiHandler";

export const getUniversityList = createAsyncThunk(
  "master/getUniversity",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getUniversity(payload);
      if (response?.code === "1") {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const initialState = {
  loading: false,
  data: [],
  selected_university:
    JSON.parse(localStorage.getItem("selected_university")) || {},
  error: null,
};

const universitySlice = createSlice({
  name: "university",
  initialState,

  reducers: {
    select_university: (state, payload) => {
      
      
      state.selected_university = payload.payload;
      localStorage.setItem(
        "selected_university",
        JSON.stringify(payload.payload)
      );
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getUniversityList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUniversityList.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload;
      })
      .addCase(getUniversityList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { select_university } = universitySlice.actions;
export default universitySlice.reducer;
