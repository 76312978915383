import React from "react";
import { useField } from "formik";
import Multiselect from "multiselect-react-dropdown";

const FormikMultiselect = ({
  options,
  className,
  style,
  onChange,
  ...props
}) => {
  const [field, meta, helpers] = useField(props);

  const handleChange = (selectedOptions) => {
    helpers.setValue(selectedOptions);
    onChange && onChange(selectedOptions);
  };

  const handleBlur = () => {
    helpers.setTouched(true);
  };

  return (
    <Multiselect
      {...field}
      selectedValues={field.value}
      options={options}
      className={className}
      style={style}
      onSelect={handleChange}
      onRemove={handleChange}
      onBlur={handleBlur}
      {...props}
      avoidHighlightFirstOption
    />
  );
};

export default FormikMultiselect;
