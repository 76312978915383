import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  addCourseBookingSloats,
  addCourses,
  courseBookingList,
  deleteCourses,
  deleteCourseSloats,
  getCourseBookingSloats,
  getTutorCourses,
  getWebCourses,
  liveCourseBookingLists,
  resecheduleCourses,
} from "../../api/apiHandler";

export const addTutorCourses = createAsyncThunk(
  "courses/addTutorCourse",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await addCourses(payload);

      if (response?.code === "1") {
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const getAllTutorCourses = createAsyncThunk(
  "courses/getTutorCourse",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getTutorCourses(payload);

      if (response?.code === "1") {
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const getAllWebCourses = createAsyncThunk(
  "courses/getWebTutorCourse",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getWebCourses(payload);

      if (response?.code === "1") {
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const addCourseSlots = createAsyncThunk(
  "courses/addCourseSlots",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await addCourseBookingSloats(payload);

      if (response?.code === "1") {
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const deleteMainCourses = createAsyncThunk(
  "courses/deleteCourses",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await deleteCourses(payload);

      if (response?.code === "1") {
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const getCourseSlots = createAsyncThunk(
  "courses/getCourseSlots",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getCourseBookingSloats(payload);

      if (response?.code === "1") {
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const deleteCourseSlots = createAsyncThunk(
  "courses/deleteCourseSlots",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await deleteCourseSloats(payload);

      if (response?.code === "1") {
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const resecheduleCourseSlot = createAsyncThunk(
  "courses/resecheduleCourseSlot",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await resecheduleCourses(payload);

      if (response?.code === "1") {
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const getCourseBookingList = createAsyncThunk(
  "courses/getCourseBookingList",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await courseBookingList(payload);

      if (response?.code === "1") {
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const getLiveCourseBookingLists = createAsyncThunk(
  "courses/getLiveCourseBookingLists",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await liveCourseBookingLists(payload);

      if (response?.code === "1") {
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

const initialState = {
  loading: false,
  data: [],
  slot: { loading: false, data: [], error: null, page: 1, total: 0 },
  booking: { loading: false, data: [], error: null, page: 1, total: 0 },
  error: null,
  page: 1,
  total: 0,
};

const courseSlice = createSlice({
  name: "courses",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addTutorCourses.pending, (state) => {
        state.loading = true;
      })
      .addCase(addTutorCourses.fulfilled, (state, { payload }) => {
        state.loading = false;
        // state.data = payload;
      })
      .addCase(addTutorCourses.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.data = [];
      })
      .addCase(addCourseSlots.pending, (state) => {
        state.slot.loading = true;
      })
      .addCase(addCourseSlots.fulfilled, (state, { payload }) => {
        state.slot.loading = false;
        // state.slot.data = [  payload];
      })
      .addCase(addCourseSlots.rejected, (state, action) => {
        state.slot.loading = false;
        state.slot.error = action.error.message;
        state.slot.data = [];
      })
      .addCase(deleteMainCourses.pending, (state) => {
        state.slot.loading = true;
      })
      .addCase(deleteMainCourses.fulfilled, (state, { payload }) => {
        state.slot.loading = false;
      })
      .addCase(deleteMainCourses.rejected, (state, action) => {
        state.slot.loading = false;
        state.slot.error = action.error.message;
      })
      .addCase(getCourseSlots.pending, (state) => {
        state.slot.loading = true;
      })
      .addCase(getCourseSlots.fulfilled, (state, { payload }) => {
        state.slot.loading = false;
        state.slot.data = payload.data.data;
        state.slot.page = payload.data.page;
        state.slot.total = payload.data.total_records;
      })
      .addCase(getCourseSlots.rejected, (state, action) => {
        state.slot.loading = false;
        state.slot.error = action.error.message;
        state.slot.data = [];
        state.slot.page = 0;
        state.slot.total = 0;
      })
      .addCase(resecheduleCourseSlot.pending, (state) => {
        state.slot.loading = true;
      })
      .addCase(resecheduleCourseSlot.fulfilled, (state, { payload }) => {
        state.slot.loading = false;
      })
      .addCase(resecheduleCourseSlot.rejected, (state, action) => {
        state.slot.loading = false;
        state.slot.error = action.error.message;
        state.slot.data = [];
        state.slot.page = 0;
        state.slot.total = 0;
      })
      .addCase(getAllTutorCourses.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllTutorCourses.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload.data.data;
        state.page = payload.data.page;
        state.total = payload.data.total_records;
      })
      .addCase(getAllTutorCourses.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.data = [];
        state.page = 0;
        state.total = 0;
      })
      .addCase(getAllWebCourses.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllWebCourses.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload.data.data;
        state.page = payload.data.page;
        state.total = payload.data.total_records;
      })
      .addCase(getAllWebCourses.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.data = [];
        state.page = 0;
        state.total = 0;
      })
      .addCase(getLiveCourseBookingLists.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLiveCourseBookingLists.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload.data.data;
        state.page = payload.data.page;
        state.total = payload.data.total_records;
      })
      .addCase(getLiveCourseBookingLists.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.data = [];
        state.page = 0;
        state.total = 0;
      })
      .addCase(getCourseBookingList.pending, (state) => {
        state.booking.loading = true;
      })
      .addCase(getCourseBookingList.fulfilled, (state, { payload }) => {
        state.booking.loading = false;
        state.booking.data = payload.data.data;
        state.booking.page = payload.data.page;
        state.booking.total = payload.data.total_records;
      })
      .addCase(getCourseBookingList.rejected, (state, action) => {
        state.booking.loading = false;
        state.booking.error = action.error.message;
        state.booking.data = [];
        state.booking.page = 0;
        state.booking.total = 0;
      });
  },
});

export default courseSlice.reducer;
