import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

// Importing translation files

import translationEN from "./locales/en/translation.json";
import translationFR from "./locales/fr/translation.json";
import translationDE from "./locales/de/translation.json";

//Creating object with the variables of imported translation files
const resources = {
  EN: {
    translation: translationEN,
  },
  FR: {
    translation: translationFR,
  },
  DE: {
    translation: translationDE,
  },
};

const storedLanguage = localStorage.getItem("selectedLanguage") || "EN";

i18n
  // .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: "EN",
    resources,
    lng: storedLanguage,
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
