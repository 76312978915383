import React from "react";
import ReactLoading from "react-loading";

const Loader = () => {
  return (
    <div className="position-absolute custom-react-loader">
      <ReactLoading
        type={"spinningBubbles"}
        color={"#066ac9"}
        height={"10%"}
        width={"10%"}
      />
    </div>
  );
};

export default Loader;
