import React, { Suspense, useEffect } from "react";

import { Outlet } from "react-router-dom";
import LandingTopbar from "./LandingTopbar";
import LandingFooter from "./LandingFooter";
import { Helmet } from "react-helmet";
import NewLoader from "../../components/Loader/NewLoader";

const loading = () => <div className=""></div>;

const DefaultLayout = (props) => {
  return (
    <>
      <Helmet>
        <script>
          {`$(document).on("scroll", function () {
            if ($(document).scrollTop() > 0) {
              $(".header").addClass("login-top-fixed");
            } else {
              $(".header").removeClass("login-top-fixed");
            }
          });`}
        </script>
      </Helmet>
      {/* <Suspense fallback={loading}>
        <LandingTopbar />
      </Suspense>
      <Suspense fallback={<NewLoader />}>
        <Outlet />
      </Suspense>
      <Suspense fallback={loading}>
        <LandingFooter />
      </Suspense> */}

      <Suspense fallback={<NewLoader />}>
        <LandingTopbar />
        <Outlet />
        <LandingFooter />
      </Suspense>
    </>
  );
};
export default DefaultLayout;
