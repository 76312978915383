import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  changeBookingStatus,
  getBookingLists,
  resecheduleIndividual,
} from "../../api/apiHandler";

export const getMyBookingLists = createAsyncThunk(
  "booking/getMyBookingLists",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getBookingLists(payload);

      if (response?.code === "1") {
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const changeStatus = createAsyncThunk(
  "booking/changeStatus",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await changeBookingStatus(payload);

      if (response?.code === "1") {
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const resecheduleIndividualBooking = createAsyncThunk(
  "booking/reschedule",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await resecheduleIndividual(payload);

      if (response?.code === "1") {
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

const initialState = {
  loading: false,
  data: [],
  error: null,
  page: 1,
  total: 0,
};

const BookingSlice = createSlice({
  name: "slots",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMyBookingLists.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMyBookingLists.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload.data.data;
        state.page = payload.data.page;
        state.total = payload.data.total_records;
      })
      .addCase(getMyBookingLists.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.data = [];
        state.page = 0;
        state.total = 0;
      })
      .addCase(changeStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(changeStatus.fulfilled, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(changeStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(resecheduleIndividualBooking.pending, (state) => {
        state.loading = true;
      })
      .addCase(resecheduleIndividualBooking.fulfilled, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(resecheduleIndividualBooking.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default BookingSlice.reducer;
