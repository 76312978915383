// PhoneInputField.js
import React from "react";
import { useField, useFormikContext } from "formik";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";

const PhoneInputField = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(props);

  const handleChange = (value) => {
    setFieldValue(field.name, value);
    props.onChange && props.onChange(value);
  };

  return (
    <div>
      <PhoneInput
        {...field}
        {...props}
        value={field.value}
        onChange={handleChange}
        defaultCountry="ch"
        inputProps={{
          className: "form-control",
          name: field.name,
          style: {
            borderLeft: "0",
            borderColor: "#EFF0F6",
            borderRadius: "0 10px 10px 0",
          },
        }}
        inputStyle={{ flexGrow: 1 }}
        countrySelectorStyleProps={{
          buttonContentWrapperClassName: ` ${
            props?.disabled ? "bg-custom-disabled" : ""
          } form-control`,

          buttonContentWrapperStyle: {
            borderTopRightRadius: "0",
            height: "50px",
            border: "1px solid #EFF0F6",
            padding: "0 20px ",
          },
          flagStyle: { height: "30px", width: "30px" },
          buttonClassName: "CountryCodeBox border-0 h-100",
        }}
      />
    </div>
  );
};

export default PhoneInputField;
