// src/authConfig.js

export const msalConfig = {
  auth: {
    clientId: "4daf9c7b-2d37-40aa-98f7-a5307a6c87ff",
    authority: "https://login.microsoftonline.com/common",
    redirectUri: "https://localhost:8061/schedule",
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
};
  