import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAllTutorSlots,
  getCourseBookedSlots,
  getCustomSlots,
  getMonthlySlots,
  setTutorAvailability,
  setTutorCustomAvailability,
} from "../../api/apiHandler";

export const saveTutorSlots = createAsyncThunk(
  "slots/saveSlots",
  async (payload, { rejectWithValue }) => {
    
    try {
      const response = await setTutorAvailability(payload);
      
      if (response?.code === "1") {
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const saveCustomSlots = createAsyncThunk(
  "slots/saveCustomSlots",
  async (payload, { rejectWithValue }) => {
    
    try {
      const response = await setTutorCustomAvailability(payload);
      
      if (response?.code === "1") {
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const getTutorMonthlySlots = createAsyncThunk(
  "slots/getMonthlySlots",
  async (payload, { rejectWithValue }) => {
    
    try {
      const response = await getMonthlySlots(payload);
      
      if (response?.code === "1") {
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const getTutorCustomSlots = createAsyncThunk(
  "slots/getCustomSlots",
  async (payload, { rejectWithValue }) => {
    
    try {
      const response = await getCustomSlots(payload);
      
      if (response?.code === "1") {
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const getTutorCustom2MonthsSlots = createAsyncThunk(
  "slots/getTutorCustom2MonthsSlots",
  async (payload, { rejectWithValue }) => {
    
    try {
      const response = await getCustomSlots(payload);
      
      if (response?.code === "1") {
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const getAllTutorSlotsData = createAsyncThunk(
  "slots/getAllSlots",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getAllTutorSlots(payload);
      
      if (response?.code === "1") {
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const getAllCourseSlotsData = createAsyncThunk(
  "slots/getAllCoursesSlots",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getCourseBookedSlots(payload);
      
      if (response?.code === "1") {
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

const initialState = {
  loading: false,
  data: [],
  custom_slot: [],
  booking_data: [],
  availibility_slots: [],
  course_slots: [],
  course_slots_2months: [],
  error: null,
  page: 1,
  total: 0,
};

const slotSlice = createSlice({
  name: "slots",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(saveTutorSlots.pending, (state) => {
        state.loading = true;
      })
      .addCase(saveTutorSlots.fulfilled, (state, { payload }) => {
        
        state.loading = false;
      })
      .addCase(saveTutorSlots.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(saveCustomSlots.pending, (state) => {
        state.loading = true;
      })
      .addCase(saveCustomSlots.fulfilled, (state, { payload }) => {
        
        state.loading = false;
      })
      .addCase(saveCustomSlots.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getTutorMonthlySlots.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTutorMonthlySlots.fulfilled, (state, { payload }) => {
        
        state.loading = false;
        state.data = payload.data;
      })
      .addCase(getTutorMonthlySlots.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.data = [];
      })
      .addCase(getTutorCustomSlots.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTutorCustomSlots.fulfilled, (state, { payload }) => {
        
        state.loading = false;
        state.custom_slot = payload.data;
      })
      .addCase(getTutorCustomSlots.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.custom_slot = [];
      })
      .addCase(getTutorCustom2MonthsSlots.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTutorCustom2MonthsSlots.fulfilled, (state, { payload }) => {
        
        state.loading = false;
        state.course_slots_2months = payload.data;
      })
      .addCase(getTutorCustom2MonthsSlots.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.course_slots_2months = [];
      })
      .addCase(getAllTutorSlotsData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllTutorSlotsData.fulfilled, (state, { payload }) => {
        
        state.loading = false;
        state.custom_slot = payload.data.custom_availablity_data;
        state.booking_data = payload.data.booking_data;
        state.availibility_slots = payload.data.availablity_data;
      })
      .addCase(getAllTutorSlotsData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.data = [];
      })
      .addCase(getAllCourseSlotsData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllCourseSlotsData.fulfilled, (state, { payload }) => {
        
        state.loading = false;
        state.course_slots = payload.data.data;
      })
      .addCase(getAllCourseSlotsData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.course_slots = [];
      });
  },
});

export default slotSlice.reducer;
