import React, { useLayoutEffect } from "react";
import ReactDOM from "react-dom/client";

import "./i18n";

import App from "./App";

import { Provider } from "react-redux";

import { BrowserRouter, useLocation } from "react-router-dom";
import store from "./store";
import { Helmet } from "react-helmet";
import Notification from "./Common/notification";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";
import { MsalProvider } from "@azure/msal-react";
import { SocketProvider } from "./Common/socketProvider";
import 'tippy.js/dist/tippy.css'; 
const pca = new PublicClientApplication(msalConfig);
const root = ReactDOM.createRoot(document.getElementById("root"));

const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

root.render(
  <>
    <MsalProvider instance={pca}>
      <Helmet>
        <script
          async
          src={process.env.PUBLIC_URL + "/assets/js/functions.js"}
        ></script>
        <script src={process.env.PUBLIC_URL + "/assets/js/script.js"}></script>
      </Helmet>
      <Provider store={store}>
        <React.Fragment>
          <BrowserRouter basename={"/"}>
            <Wrapper>
              <Notification />
              <SocketProvider>
                <App />
              </SocketProvider>
            </Wrapper>
          </BrowserRouter>
        </React.Fragment>
      </Provider>
    </MsalProvider>
  </>
);
