import React, { Suspense, useEffect } from "react";
import { useDispatch } from "react-redux";
// import { Container } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import { getUserProfile } from "../../store/slice/authSlice";

const Topbar = React.lazy(() => import("./DashboardTopbar"));
const LeftSidebar = React.lazy(() => import("./LeftSidebar"));
const Footer = React.lazy(() => import("./DashboardFooter"));

const loading = () => <div className=""></div>;

const VerticalLayout = ({ children }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUserProfile({}));
  }, [dispatch]);

  return (
    <>
      <div id="wrapper">
        <Suspense fallback={loading()}>
          <LeftSidebar />
        </Suspense>

        <div className="content-page">
          <Suspense fallback={loading()}>
            <Topbar />
          </Suspense>

          <div className="content mb-5">
            {/* <Container fluid> */}
            <Suspense fallback={loading()}>
              <Outlet />
            </Suspense>
            {/* </Container> */}
          </div>

          <Suspense fallback={loading()}>
            <Footer />
          </Suspense>
        </div>
      </div>
    </>
  );
};
export default VerticalLayout;
