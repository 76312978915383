import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Outlet, useNavigate } from "react-router-dom";
import { getUserProfile } from "../store/slice/authSlice";
// import { getToken } from "../common/LocalStorageService";

const PrivateLayout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isRender, setIsRender] = useState(false);

  const isLoggedIn = useSelector((state) => state.auth.userToken);

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/");
    } else {
      setIsRender(true);
    }
  }, [dispatch, isLoggedIn, navigate]);

  if (!isRender) {
    return null;
  }
  return <Outlet />;
};

export default PrivateLayout;
