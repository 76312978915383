import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { addStudentReview } from "../../api/apiHandler";

export const addStudentRatingReview = createAsyncThunk(
  "review/add-review",
  async (payload, { rejectWithValue }) => {
    
    try {
      const response = await addStudentReview(payload);
      
      if (response?.code === "1") {
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

const initialState = {
  loading: false,
  data: [],
  error: null,
};

const studentSlice = createSlice({
  name: "review",
  initialState,

  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(addStudentRatingReview.pending, (state) => {
        state.loading = true;
      })
      .addCase(addStudentRatingReview.fulfilled, (state, { payload }) => {
        
        state.loading = false;
        state.data = payload;
      })
      .addCase(addStudentRatingReview.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.data = [];
      });
  },
});

export default studentSlice.reducer;
