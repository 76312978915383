import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const CourseModal = (props) => {
  const { isCourse, setIsCourse } = props;
  const { t } = useTranslation();

  const {
    filter_category: { data: categoriesFilter },
  } = useSelector((state) => state.master);
  return (
    <div class="sub-course-modal" onClick={() => setIsCourse(false)}>
      <div
        className={`modal ${isCourse ? "fade show d-block " : "d-none"}`}
        id="sub-course-modal"
        tabindex="-1"
        aria-labelledby="sub-course-modalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog   modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="text-center">{t("chooseYourSemester")}</h5>
            </div>
            <div class="modal-body">
              <ul>
                {categoriesFilter?.length > 0 ? (
                  categoriesFilter?.map((item) => {
                    return (
                      <li>
                        <Link
                          to={"/courses"}
                          state={{ semester: item }}
                          // onClick={}
                          class="sub-course-anchor"
                        >
                          {item?.normal_semester}
                        </Link>
                      </li>
                    );
                  })
                ) : (
                  <div
                    style={{
                      height: "100px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {t("noDataFound")}
                  </div>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
      {isCourse && <div className="modal-backdrop fade show"></div>}
    </div>
  );
};

export default CourseModal;
