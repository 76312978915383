// import React from "react";
// import { useField, useFormikContext } from "formik"; // Import Formik hooks if needed
// import { bindNiceSelect, triggerChange } from "./NiceSelectUtil";
import "./NiceSelect.css";

// export default function FormikNiceSelect({ id, children, ...props }) {
//   const [field] = useField(props);
//   const { setFieldValue } = useFormikContext();

//   React.useEffect(() => {
//     const el = document.getElementById(id);
//     bindNiceSelect(el);
//     el.addEventListener("change", (e) => {
//       setFieldValue(props.name, e.target.value);
//     });
//   }, [id, setFieldValue, props.name]);

//   return (
//     <div>
//       <select id={id} {...field} {...props}>
//         {children}
//       </select>
//     </div>
//   );
// }

// export default function FormikNiceSelect({ id, children, ...props }) {
//   const [field, meta, helpers] = useField(props);
//   const { setFieldValue } = useFormikContext();

//   // Utility function to trigger change event
//   // const triggerChange = (el) => {
//   //   const event = new Event("change", { bubbles: true });
//   //   el.dispatchEvent(event);
//   // };

//   React.useEffect(() => {
//     const el = document.getElementById(id);
//     const niceSelect = bindNiceSelect(el);

//     // Update the NiceSelect instance when the field value changes
//     niceSelect.update();

//     el.addEventListener("change", (e) => {
//       setFieldValue(props.name, e.target.value);
//     });

//     // Set initial value
//     const initialValue = field.value;
//     
//     if (initialValue) {
//       setFieldValue(props.name, initialValue);
//       el.value = initialValue;
//       triggerChange(el);
//     }

//     return () => {
//       // Clean up event listeners and NiceSelect instance
//       el.removeEventListener("change", setFieldValue);
//       niceSelect.destroy();
//     };
//   }, [id, setFieldValue, props.name, field.value]);

//   return (
//     <div>
//       <select id={id} {...field} {...props}>
//         {children}
//       </select>
//     </div>
//   );
// }

import React, { useCallback } from "react";

import Select from "react-select";
// import { colourOptions } from "../data";
import { bindNiceSelect } from "./NiceSelectUtil";
import { useField } from "formik";

export const NiceSelect = ({ id, children, ...props }) => {
  const { name, onBlur, onChange, value } = props;

  React.useEffect(() => {
    const el = document.getElementById(id);
    bindNiceSelect(el);
  }, [id]);

  return (
    <select
      id={id}
      name={name}
      {...props}
      onBlur={onBlur}
      onChange={onChange}
      value={value}
    >
      {children}
    </select>
  );
};

const FormikSelect = ({ options, onChange, newStyle, ...props }) => {
  const [field, , helpers] = useField(props);

  const customStyles = {
    input: (baseStyles, state) => {
      return {
        ...baseStyles,
        // height: "50px",
      };
    },
    control: (baseStyles, state) => {
      return {
        ...baseStyles,
        border: "1px solid #eff0f6",
      };
    },
    valueContainer: (baseStyles, state) => {
      return {
        ...baseStyles,
        height: "50px",
      };
    },
    menu: (provided) => ({
      ...provided,
      top:"auto"
      // border: "1px solid #747579",
    }),

    ...newStyle,
  };

  const handleChange = useCallback(
    (option) => {
      
      helpers.setValue(option);
      onChange && onChange(option);
    },
    [helpers, onChange]
  );

  return (
    <Select
      {...field}
      // menuIsOpen
      {...props}
      classNamePrefix="select"
      options={options}
      styles={customStyles}
      onChange={handleChange}
      onBlur={() => helpers.setTouched(true)}
    />
  );
};

export default FormikSelect;
