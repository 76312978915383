import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  addUpdatefavorite,
  findMyTutor,
  getFavouriteTutorList,
  getSemester,
  getTutorRatingList,
  myStudent,
} from "../../api/apiHandler";

export const getFindMyTutorList = createAsyncThunk(
  "tutor/findMyTutor",
  async (payload, { rejectWithValue }) => {

    try {
      console.log('<<<<<<<<', payload)
      const response = await findMyTutor(payload);

      if (response?.code === "1") {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getFindMyTutorById = createAsyncThunk(
  "tutor/findMyTutorById",
  async (payload, { rejectWithValue }) => {
    
    try {
      const response = await findMyTutor(payload);
      
      if (response?.code === "1") {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const tutorRatingList = createAsyncThunk(
  "tutor/tutorRatingList",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getTutorRatingList(payload);
      
      if (response?.code === "1") {
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const favouriteUnfavouriteTutor = createAsyncThunk(
  "tutor/addUpdatefavorite",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await addUpdatefavorite(payload);
      
      if (response?.code === "1") {
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const getFavouriteTutor = createAsyncThunk(
  "tutor/getFavouriteTutorList",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getFavouriteTutorList(payload);
      
      if (response?.code === "1") {
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const getMyStudents = createAsyncThunk(
  "tutor/getMyStudents",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await myStudent(payload);
      
      if (response?.code === "1") {
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

const initialState = {
  loading: false,
  data: [],
  error: null,
  page: 1,
  total: 0,
  individual_tutor: {
    data: [],
    loading: false,
    error: null,
    page: 1,
    total: 0,
  },
  review: {
    data: [],
    error: null,
    page: 1,
    total: 0,
  },
  students: {
    data: [],
    error: null,
    page: 1,
    total: 0,
  },
};

const TutorSlice = createSlice({
  name: "tutor",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getFindMyTutorList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFindMyTutorList.fulfilled, (state, { payload }) => {
        
        state.loading = false;
        state.data = payload.data;
        state.page = payload.page;
        state.total = payload.total_records;
      })
      .addCase(getFindMyTutorList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.data = [];
        state.total = 0;
      })
      .addCase(getFindMyTutorById.pending, (state) => {
        state.individual_tutor.loading = true;
      })
      .addCase(getFindMyTutorById.fulfilled, (state, { payload }) => {
        
        state.individual_tutor.loading = false;
        state.individual_tutor.data = payload.data;
        state.individual_tutor.page = payload.page;
        state.individual_tutor.total = payload.total_records;
      })
      .addCase(getFindMyTutorById.rejected, (state, action) => {
        state.individual_tutor.loading = false;
        state.individual_tutor.error = action.error.message;
        state.individual_tutor.data = [];
        state.individual_tutor.total = 0;
      })
      .addCase(getFavouriteTutor.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFavouriteTutor.fulfilled, (state, { payload }) => {
        
        state.loading = false;
        state.data = payload.data.data;
        state.page = payload.data.page;
        state.total = payload.data.total_records;
      })
      .addCase(getFavouriteTutor.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.data = [];
        state.total = 0;
      })
      .addCase(favouriteUnfavouriteTutor.pending, (state) => {
        state.loading = true;
      })
      .addCase(favouriteUnfavouriteTutor.fulfilled, (state, { payload }) => {
        
        state.loading = false;
      })
      .addCase(favouriteUnfavouriteTutor.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.data = [];
        state.total = 0;
      })
      .addCase(tutorRatingList.pending, (state) => {
        state.review.loading = true;
      })
      .addCase(tutorRatingList.fulfilled, (state, { payload }) => {
        
        state.review.loading = false;
        state.review.data = payload.data;
        state.review.page = payload.data.page;
        state.review.total = payload.data.total_records;
      })
      .addCase(tutorRatingList.rejected, (state, action) => {
        state.review.loading = false;
        state.review.error = action.error.message;
        state.review.data = [];
        state.review.total = 0;
      })
      .addCase(getMyStudents.pending, (state) => {
        state.students.loading = true;
      })
      .addCase(getMyStudents.fulfilled, (state, { payload }) => {
        
        state.students.loading = false;
        state.students.data = payload.data.data;
        state.students.page = payload.data.page;
        state.students.total = payload.data.total_records;
      })
      .addCase(getMyStudents.rejected, (state, action) => {
        state.students.loading = false;
        state.students.error = action.error.message;
        state.students.data = [];
        state.students.total = 0;
      });
  },
});

export default TutorSlice.reducer;
