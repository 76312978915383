import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import FormikErrorMessage from "../../../components/FormikValidationMessage";
import {
  showErrorMessage,
  showPromiseNotification,
} from "../../../Common/notification";
import { PhoneNumberUtil } from "google-libphonenumber";
import PhoneInputField from "../../../components/MobileNumber";
import { useDispatch, useSelector } from "react-redux";
import FormikSelect from "../../../components/NiceSelect";
import { getCountryList, getStateList } from "../../../store/slice/masterSlice";
import { editStudent } from "../../../store/slice/authSlice";
import uploadToS3, {
  generateRandomFileName,
} from "../../../Common/s3AwsService";
import Loader from "../../../components/Loader";
import { getUniversityList } from "../../../store/slice/universitySlice";
import FormikMultiselect from "../../../components/FormMultiSelect";
import { getSemesterList } from "../../../store/slice/semesterSlice";
import { getSubjectList } from "../../../store/slice/subjectSlice";
import ImageCropper from "../../../components/ImageCropper";
import { useTranslation } from "react-i18next";

const StudentEditProfile = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { userInfo, isLoading } = useSelector((state) => state.auth);
  const { country } = useSelector((state) => state.master);
  const { state } = useSelector((state) => state.master);

  const [imageFile, setImageFile] = useState(null);
  const [open, setOpen] = useState(false);

  const {
    semester: { data: semesterData },
    subject: { data: subjectData },
    university: { data: universityData },
  } = useSelector((state) => state);

  const {
    loading: countryLoading,
    data: countryData,
    success: countrySuccess,
  } = country || {};

  const {
    loading: stateLoading,
    data: stateData,
    success: stateSuccess,
  } = state || {};

  const initialValues = useMemo(() => {
    return {
      firstName: userInfo?.first_name || "",
      lastName: userInfo?.last_name || "",
      email: userInfo?.email || "",
      phoneNumber: userInfo?.mobile_number || "",
      state: userInfo?.state_id
        ? { id: userInfo?.state_id, name: userInfo?.state_name }
        : "",
      country: userInfo?.country_id
        ? { id: userInfo?.country_id, name: userInfo?.country_name }
        : "",
      university: userInfo?.school_university_id
        ? {
            id: userInfo?.school_university_id,
            university_name: userInfo?.school_university_name,
          }
        : "",
      subject: userInfo?.user_subject?.main || [],
      semester: userInfo?.user_semesters?.main || [],
      address: userInfo?.address || "",
      personalInfo: userInfo?.personal_information || "",
      userPhoto: userInfo?.profile_image || "",
      userPhotoChange: false,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    userInfo?.address,
    userInfo?.country_id,
    userInfo?.country_name,
    userInfo?.email,
    userInfo?.first_name,
    userInfo?.last_name,
    userInfo?.mobile_number,
    userInfo?.personal_information,
    userInfo?.profile_image,
    userInfo?.school_university_id,
    userInfo?.school_university_name,
    userInfo?.state_id,
    userInfo?.state_name,
    userInfo?.user_semesters?.main,
    userInfo?.user_subject?.main,
  ]);

  const phoneUtil = PhoneNumberUtil.getInstance();

  const isPhoneValid = useCallback(
    (phone) => {
      try {
        return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
      } catch (error) {
        return false;
      }
    },
    [phoneUtil]
  );

  const validationSchema = Yup.object({
    userPhoto: Yup.mixed().required(t("userphotoDocRequired")),
    firstName: Yup.string()
      .transform((value) => value.trim())
      .required(t("firstNameRequired")),
    lastName: Yup.string()
      .transform((value) => value.trim())
      .required(t("lastNameRequired")),
    phoneNumber: Yup.string()
      .required(t("phoneNumberRequired"))
      .test("phoneValidation", t("phoneNumberValidation"), (phone) => {
        if (phone) {
          return isPhoneValid(phone);
        } else {
          return true;
        }
      }),
    email: Yup.string()
      .email(t("invalidEmail"))
      .required(t("emailIsRequired"))
      .test(
        "emailDomainValidation",
        t("emailDomainValidation"),
        function (value) {
          const { university } = this.parent;
          if (university && university.allow_email) {
            const emailDomain = university.allow_email;
            return value && value.endsWith(emailDomain);
          }
          return true;
        }
      ),
    university: Yup.object().required(t("pleaseSelectUniversity")),
    semester: Yup.array().of(Yup.object()).min(1, t("semesterValidation")),
    subject: Yup.array().of(Yup.object()).min(1, t("subjectsValidation")),
    state: Yup.object().required(t("stateRequired")),
    country: Yup.object().required(t("countryRequired")),
    address: Yup.string().required(t("addressRequired")),
    personalInfo: Yup.string().required(t("personalInfoRequired")),
  });

  const getStateFromCountry = useCallback(
    (data) => {
      dispatch(getStateList({ country_id: data.id }));
    },
    [dispatch]
  );

  const handleSubmit = useCallback(
    (values, { setSubmitting }) => {
      const myPromise = new Promise(async (resolve, reject) => {
        const payload = {
          first_name: values?.firstName,
          last_name: values?.lastName,
          mobile_number: values?.phoneNumber,
          personal_information: values?.personalInfo,
          address: values?.address,
          state_id: values?.state?.id,
          country_id: values?.country?.id,
          email: values?.email,
          school_university_id: values?.university?.id,
          school_university_name: values?.university?.university_name,
          semesters: values?.semester?.map((item) => item.id).join(","),
          subjects: values?.subject?.map((item) => {
            return {
              subject_id: item.id,
              subject_language: item?.lang_flag || "en",
            };
          }),
        };

        if (values?.userPhotoChange) {
          await uploadToS3(
            values?.userPhotoFile,
            `${generateRandomFileName()}.${
              values?.userPhotoFile.type.split("/")[1]
            }`,
            "user"
          ).then((response) => {
            payload.profile_image = response.key;
          });
        }
        dispatch(editStudent(payload)).then((result) => {
          if (result.payload.code === "1") {
            resolve(result.payload);

            setSubmitting(false);
          } else {
            setSubmitting(false);
            reject(result.payload);
          }
        });
      });
      showPromiseNotification(myPromise);
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(getCountryList({}));
  }, [dispatch]);

  useEffect(() => {
    if (userInfo?.country_id) {
      dispatch(getStateList({ country_id: userInfo?.country_id }));
    }
  }, [dispatch, userInfo?.country_id]);

  useEffect(() => {
    dispatch(getUniversityList({}));
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getSemesterList({
        find_by_university_id: userInfo?.school_university_id,
      })
    );
  }, [dispatch, userInfo?.school_university_id]);

  useEffect(() => {
    if (
      userInfo?.school_university_id &&
      userInfo?.user_semesters?.main?.length
    ) {
      dispatch(
        getSubjectList({
          university_id: userInfo?.school_university_id,
          semester_ids: userInfo?.user_semesters?.main
            ?.map((item) => item.id)
            .join(","),
        })
      );
    }
  }, [
    dispatch,
    userInfo?.school_university_id,
    userInfo?.user_semesters?.main,
  ]);
  // const SectionUseEffect = (props) => {
  //   // const formik = useFormikContext();
  //   const { values } = props;
  //   return <></>;
  // };

  return (
    <section className="tutor-my-profile-section">
      <div className="page-content">
        <div className="page-content-wrapper">
          <div className="container-fluid p-0">
            <div className="row">
              <div className="col-12">
                <div className="tutor-dash-header">
                  <h2 className="tutor-heading">{t("updateUrProfile")}</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="edit-profile-main">
                  <div className="apply-form">
                    <div id="container" className="container position-relative">
                      {(isLoading || countryLoading || stateLoading) && (
                        <Loader />
                      )}
                      <Formik
                        initialValues={initialValues}
                        enableReinitialize
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                      >
                        {(formik) => {
                          const {
                            values,
                            setFieldValue,
                            isSubmitting,
                            setFieldError,
                          } = formik;

                          const handleCropComplete = (croppedImage) => {
                            setFieldValue(
                              "userPhoto",
                              URL.createObjectURL(croppedImage)
                            );
                            setFieldValue("userPhotoFile", croppedImage);
                            setFieldError("userPhoto", "", false);
                            setFieldValue("userPhotoChange", true);
                          };

                          const handleFileImageChange = (event) => {
                            const selectedFiles = event.target.files;
                            if (selectedFiles.length > 0) {
                              const file = selectedFiles[0];
                              if (file.type.startsWith("image/")) {
                                setImageFile(file);
                                setOpen(true);
                              } else {
                                setFieldValue("userPhotoChange", false);
                                showErrorMessage(t("onlyImageAllowed"));
                              }
                            }
                          };

                          return (
                            <>
                              {imageFile && (
                                <ImageCropper
                                  imageFile={imageFile}
                                  onCropComplete={handleCropComplete}
                                  open={open}
                                  handleClose={() => setOpen(false)}
                                />
                              )}
                              {/* <SectionUseEffect values={values} /> */}
                              <Form id="multi-step-form">
                                <div className="step step-1">
                                  <h3 className="personal-text border-0">
                                    {t("personalInfo")}
                                  </h3>
                                  <div className="row">
                                    <div className="col-12">
                                      <div className="apply-user-main">
                                        <div className="apply-user-photo">
                                          <img
                                            src={
                                              values.userPhoto ||
                                              process.env.PUBLIC_URL +
                                                "/assets/images/icons/apply-user-photo.svg"
                                            }
                                            className="img-100 border-50"
                                            alt="apply-user-photo"
                                          />
                                          <div className="apply-add-photo">
                                            <img
                                              src={
                                                process.env.PUBLIC_URL +
                                                "/assets/images/icons/camera.svg"
                                              }
                                              alt="camera"
                                              className="border-50"
                                            />
                                            <div className="apply-camera-input">
                                              <input
                                                type="file"
                                                id="userPhoto"
                                                name="userPhoto"
                                                onChange={handleFileImageChange}
                                                className="form-control-file"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <FormikErrorMessage
                                          name="userPhoto"
                                          component="div"
                                          className="error-message text-center mt-3"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-xxl-4 col-xl-4 col-lg-4  col-md-6 col-sm-12 col-12 mt-30px">
                                      <label
                                        htmlFor="firstName"
                                        className="form-label"
                                      >
                                        {t("firstName")}
                                      </label>
                                      <div className="position-relative">
                                        <Field
                                          type="text"
                                          className="form-control apply-input"
                                          placeholder="John"
                                          id="firstName"
                                          name="firstName"
                                        />

                                        <div className="apply-icon">
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/assets/images/icons/avatar.svg"
                                            }
                                            alt="avatar"
                                          />
                                        </div>
                                      </div>
                                      <FormikErrorMessage
                                        name="firstName"
                                        component="div"
                                        className="error-message"
                                      />
                                    </div>
                                    <div className="col-xxl-4 col-xl-4 col-lg-4  col-md-6 col-sm-12 col-12 mt-30px">
                                      <label
                                        htmlFor="lastName"
                                        className="form-label"
                                      >
                                        {t("lastName")}
                                      </label>
                                      <div className="position-relative">
                                        <Field
                                          type="text"
                                          className="form-control apply-input"
                                          placeholder="Crater"
                                          id="lastName"
                                          name="lastName"
                                        />

                                        <div className="apply-icon">
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/assets/images/icons/avatar.svg"
                                            }
                                            alt="avatar"
                                          />
                                        </div>
                                      </div>
                                      <FormikErrorMessage
                                        name="lastName"
                                        component="div"
                                        className="error-message"
                                      />
                                    </div>
                                    <div className="col-xxl-4 col-xl-4 col-lg-4  col-md-6 col-sm-12 col-12 mt-30px">
                                      <label
                                        htmlFor="email"
                                        className="form-label"
                                      >
                                        {t("email")}
                                      </label>
                                      <div className="input-group input-group-lg">
                                        <Field
                                          type="email"
                                          id="email"
                                          name="email"
                                          disabled
                                          className="form-control login-input"
                                          placeholder="John@email.com"
                                        />
                                        <div className="login-icon">
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/assets/images/icons/email-icon.svg"
                                            }
                                            alt="email-img"
                                          />
                                        </div>
                                      </div>
                                      <FormikErrorMessage
                                        name="email"
                                        component="div"
                                        className="error-message"
                                      />
                                    </div>
                                    <div className="col-xxl-6 col-xl-6 col-lg-6  col-md-6 col-sm-12 col-12 mt-30px">
                                      <label
                                        htmlFor="phoneNumber"
                                        className="form-label"
                                      >
                                        {t("phoneNumber")}
                                      </label>
                                      <div className="position-relative">
                                        <PhoneInputField name="phoneNumber" />

                                        <div className="apply-icon">
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/assets/images/icons/phone-icon.svg"
                                            }
                                            alt="phone-icon"
                                          />
                                        </div>
                                      </div>
                                      <FormikErrorMessage
                                        name="phoneNumber"
                                        component="div"
                                        className="error-message "
                                      />
                                    </div>
                                    <div className="col-xxl-6 col-xl-6 col-lg-6  col-md-6 col-sm-12 col-12 mt-30px">
                                      <label
                                        htmlFor="university"
                                        className="form-label"
                                      >
                                        {t("schoolUnviversity")}
                                      </label>
                                      <FormikSelect
                                        id="a-select"
                                        name="university"
                                        placeholder={t("selectYourUniversity")}
                                        className="basic-single height-50 "
                                        disabled={!universityData.length}
                                        options={universityData}
                                        onChange={(value) => {
                                          // dispatch(select_university(value));
                                          dispatch(
                                            getSemesterList({
                                              find_by_university_id: value?.id,
                                            })
                                          );
                                          setFieldValue("semester", []);
                                          setFieldValue("subject", []);
                                        }}
                                        getOptionLabel={(item) =>
                                          item.university_name
                                        }
                                        getOptionValue={(item) => item.id}
                                      ></FormikSelect>
                                      <FormikErrorMessage
                                        name="university"
                                        component="div"
                                        className="error-message"
                                      />
                                    </div>
                                    <div className="col-xxl-6 col-xl-6 col-lg-6  col-md-6 col-sm-12 col-12 mt-30px">
                                      <label
                                        htmlFor="semester"
                                        className="form-label"
                                      >
                                        {t("subjectsSemester")}
                                      </label>
                                      <div className="position-relative">
                                        <FormikMultiselect
                                          displayValue="semester"
                                          name="semester"
                                          placeholder={t("selectSemester")}
                                          className=" multi-select"
                                          options={semesterData}
                                          onChange={(value) => {
                                            dispatch(
                                              getSubjectList({
                                                university_id:
                                                  values?.university?.id,
                                                semester_ids: value
                                                  ?.map((item) => item.id)
                                                  .join(","),
                                              })
                                            );
                                            setFieldValue("subject", []);
                                          }}
                                          style={{
                                            multiselectContainer: {},
                                            searchBox: {
                                              minHeight: "50px",
                                              display: "flex",
                                              alignItems: "center",
                                              flexWrap: "wrap",
                                            },
                                          }}
                                        />

                                        <div className="apply-icon">
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/assets/images/icons/book.svg"
                                            }
                                            alt="book"
                                          />
                                        </div>
                                      </div>
                                      <FormikErrorMessage
                                        name="semester"
                                        component="div"
                                        className="error-message"
                                      />
                                    </div>
                                    <div className="col-xxl-6 col-xl-6 col-lg-6  col-md-6 col-sm-12 col-12 mt-30px">
                                      <label
                                        htmlFor="subject"
                                        className="form-label"
                                      >
                                        {t("subject")}
                                      </label>
                                      <div className="position-relative">
                                        <FormikMultiselect
                                          displayValue="orignal_selected_subject"
                                          name="subject"
                                          placeholder={t("selectSubject")}
                                          className=" multi-select"
                                          options={subjectData}
                                          style={{
                                            multiselectContainer: {},
                                            searchBox: {
                                              minHeight: "50px",
                                              display: "flex",
                                              alignItems: "center",
                                              flexWrap: "wrap",
                                            },
                                          }}
                                        />
                                        <div className="apply-icon">
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/assets/images/icons/subject.svg"
                                            }
                                            alt="subject"
                                          />
                                        </div>
                                      </div>
                                      <FormikErrorMessage
                                        name="subject"
                                        component="div"
                                        className="error-message"
                                      />
                                    </div>
                                    <div className="col-xxl-6 col-xl-6 col-lg-6  col-md-6 col-sm-12 col-12 mt-30px">
                                      <label
                                        htmlFor="country"
                                        className="form-label"
                                      >
                                        {t("country")}
                                      </label>
                                      <div className="position-relative">
                                        <FormikSelect
                                          id="a-select"
                                          name="country"
                                          placeholder={t("selectCountry")}
                                          className="basic-single height-50 "
                                          onChange={getStateFromCountry}
                                          isDisabled={
                                            !countrySuccess &&
                                            !countryData?.data?.length
                                          }
                                          options={countryData?.data}
                                          getOptionLabel={(item) => item.name}
                                          getOptionValue={(item) => item.id}
                                        ></FormikSelect>
                                      </div>
                                      <FormikErrorMessage
                                        name="country"
                                        component="div"
                                        className="error-message"
                                      />
                                    </div>
                                    <div className="col-xxl-6 col-xl-6 col-lg-6  col-md-6 col-sm-12 col-12 mt-30px">
                                      <label
                                        htmlFor="state"
                                        className="form-label"
                                      >
                                        {t("state")}
                                      </label>
                                      <div className="position-relative">
                                        <FormikSelect
                                          id="a-select"
                                          name="state"
                                          placeholder={t("selectState")}
                                          className="basic-single height-50 "
                                          isDisabled={
                                            // stateLoading &&
                                            !stateSuccess &&
                                            !stateData?.data?.length
                                          }
                                          isLoading={stateLoading}
                                          isSearchable
                                          options={stateData?.data}
                                          getOptionLabel={(item) => item.name}
                                          getOptionValue={(item) => item.id}
                                        ></FormikSelect>
                                      </div>
                                      <FormikErrorMessage
                                        name="state"
                                        component="div"
                                        className="error-message"
                                      />
                                    </div>

                                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mt-30px">
                                      <div className="position-relative">
                                        <label
                                          htmlFor="address"
                                          className="form-label"
                                        >
                                          {t("address")}
                                        </label>
                                        <div className="apply-textarea">
                                          <Field
                                            name="address"
                                            id="address"
                                            as="textarea"
                                            cols={30}
                                            rows={3}
                                            className="form-control apply-input"
                                          />
                                          <FormikErrorMessage
                                            name="address"
                                            component="div"
                                            className="error-message"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mt-30px">
                                      <div className="position-relative">
                                        <label
                                          htmlFor="personalInfo"
                                          className="form-label"
                                        >
                                          {t("personalInfo")}
                                        </label>
                                        <div className="apply-textarea">
                                          <Field
                                            name="personalInfo"
                                            id="personalInfo"
                                            as="textarea"
                                            cols={30}
                                            rows={3}
                                            className="form-control apply-input"
                                          />
                                          <FormikErrorMessage
                                            name="personalInfo"
                                            component="div"
                                            className="error-message"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="back-next-between-btn-div  justify-content-center ">
                                      <button
                                        type="submit"
                                        className="apply-form-next-btn  border-8px"
                                        disabled={isSubmitting}
                                      >
                                        {t("updateBtn")}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </Form>
                            </>
                          );
                        }}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*?php include 'inc/dashboard-footer.php'; ?*/}
          </div>
        </div>
      </div>
    </section>
  );
};

export default StudentEditProfile;
