import React, { useEffect, useState } from "react";
import { getFAQ } from "../../../api/apiHandler";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Faqs = () => {
  const [faqs, setFaqs] = useState();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    getFAQ({ page: 1 }).then((res) => {
      if (res.code === "1") {
        setFaqs(res.data.data);
      }
    });
  }, []);

  return (
    <section className="faq-section">
      <div className="container mt-4">
        {/* Title */}
        <div className="row mb-4 mb-sm-5 ">
          <div className="col-12 text-center mx-auto">
            <h2
              className="index-heading-2"
              dangerouslySetInnerHTML={{ __html: t("frequentlyAskedQuestion") }}
            ></h2>
            <p className="we-are-text">
              {t("faqSubTitle")}{" "}
              <Link to={"/contact-us"} className="contact-anchor">
                {t("contactOurSupport")}
              </Link>
            </p>
          </div>
        </div>
        <div className="row g-4 g-md-5">
          {/* FAQ item */}
          {faqs?.map((item) => {
            return (
              <div className="col-md-6">
                <div className="faq-sub-main">
                  <div className="faq-sub-left">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/icons/faq-img.svg"
                      }
                      alt="faq-img"
                      className="faq-img"
                    />
                  </div>
                  <div className="faq-sub-right">
                    <h5 className="faq-sub-heading">{item?.en_question}</h5>
                    <p className="faq-sub-text">{item?.en_answer}</p>
                  </div>
                </div>
              </div>
            );
          })}
          {/* FAQ item */}
        </div>
      </div>
    </section>
  );
};

export default Faqs;
