import { configureStore } from "@reduxjs/toolkit";
import changeLanguageReducer from "./slice/Changelanguage";
import authSlice from "./slice/authSlice";
import universitySlice from "./slice/universitySlice";
import semesterSlice from "./slice/semesterSlice";
import subjectSlice from "./slice/subjectSlice";
import masterSlice from "./slice/masterSlice";
import tutorSlice from "./slice/tutorSlice";
import studentSlice from "./slice/studentSlice";
import blogSlice from "./slice/blogSlice";
import slotSlice from "./slice/slotSlice";
import BookingSlice from "./slice/bookingSlice";
import coursesSlice from "./slice/coursesSlice";
import notificationSlice from "./slice/notificationSlice";
import chatSlices from "./slice/chatSlices";

const store = configureStore({
  reducer: {
    auth: authSlice,
    changelanguage: changeLanguageReducer,
    university: universitySlice,
    semester: semesterSlice,
    subject: subjectSlice,
    master: masterSlice,
    tutor: tutorSlice,
    student: studentSlice,
    blog: blogSlice,
    slot: slotSlice,
    booking: BookingSlice,
    course: coursesSlice,
    notification: notificationSlice,
    chat: chatSlices,
  },
});

export default store;
