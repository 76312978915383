import React, { useState } from "react";
import WhatStudentSaySection from "./components/WhatStudentSaySection";
import OurSponsor from "./components/OurSponsor";
import ExperiencedTutorSection from "./components/ExperiencedTutorSection";
import Faqs from "./components/Faqs";
import Blog from "./components/Blog";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SelectUniversityModal from "./components/SelectUniversityModal";
import { useSelector } from "react-redux";
// import VideoPlayer from "../../components/VideoPlayer";

const LandingPage = () => {
  const { t } = useTranslation();
  const { selected_university: selectedUniversity } = useSelector(
    (state) => state.university
  );
  const [isUniversityModalOpen, setIsUniversityModalOpen] = useState(
    !Boolean(selectedUniversity?.id)
  );

  // const options = {
  //   autoplay: true, // Enable autoplay
  //   muted: true,
  //   loop: true,
  //   responsive: true,
  //   fluid: true,
  //   sources: [
  //     {
  //       src: process.env.PUBLIC_URL + "/assets/video/baner.mp4",
  //       type: "video/mp4",
  //     },
  //   ],
  // };

  return (
    <>
      <div>
        {/* <VideoPlayer options={options} /> */}

        <section className="discover-section">
          <div className="container-fluid">
            <div className="row g-4 g-md-5 align-items-center justify-content-between mb-xxl-n7">
              <div className="col-lg-3 col-xl-4 col-xxl-3 d-none d-lg-block">
                <div className="row g-md-5 align-items-center ms-n6">
                  <div className="col-6">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assets/images/bg/3by4/07.jpg"
                      }
                      className="rounded-3 mt-6 mb-5"
                      alt="3by4/07.jpg"
                    />
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assets/images/bg/3by4/06.jpg"
                      }
                      className="rounded-3"
                      alt="3by4/06.jpg"
                    />
                  </div>
                  <div className="col-6 position-relative">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assets/images/bg/3by4/05.jpg"
                      }
                      className="rounded-3"
                      alt="3by4/05.jpg"
                    />
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assets/images/element/17.svg"
                      }
                      className="h-60px position-absolute top-0 start-0 ms-3 mt-n3"
                      alt="17.svg"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-xl-4 col-xxl-5 text-center position-relative">
                <h1 className="discover-h1">{t("discoverBestClasses")}</h1>
                <p className="online-p">{t("discoverBestClassesSubTitle")}</p>
                <div className="discover-main">
                  <div className="discover-left">
                    <Link
                      to={"/find-a-tutor"}
                      className="apply-to-teach-anchor"
                    >
                      {t("findATutor")} &nbsp;
                      <svg
                        className="svg-20px"
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        width={512}
                        height={512}
                        x={0}
                        y={0}
                        viewBox="0 0 512 512"
                        style={{ enableBackground: "new 0 0 512 512" }}
                        xmlSpace="preserve"
                      >
                        <g>
                          <path
                            d="M398.5 281h-298c-13.81 0-25-11.19-25-25s11.19-25 25-25h298c13.81 0 25 11.19 25 25s-11.19 25-25 25z"
                            fill="#ffffff"
                            opacity={1}
                            data-original="#000000"
                          />
                          <path
                            d="M284.01 399.25a24.96 24.96 0 0 1-18.34-8c-9.39-10.12-8.79-25.94 1.33-35.33l107.47-99.67-110.72-99.94c-10.25-9.25-11.06-25.06-1.81-35.31s25.06-11.06 35.31-1.81l131 118.25c5.21 4.7 8.2 11.37 8.25 18.39s-2.85 13.73-8 18.5L301 392.58a24.917 24.917 0 0 1-16.99 6.67z"
                            fill="#ffffff"
                            opacity={1}
                            data-original="#000000"
                          />
                        </g>
                      </svg>
                    </Link>
                  </div>
                  <div className="discover-right">
                    <a
                      href="javascript:void(0)"
                      className="discover-video-anchor"
                    >
                      <i className="fas fa-play" />
                    </a>
                    <h6 className="whatch-video-heading">{t("watchVideo")}</h6>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-xl-4 col-xxl-3 d-none d-lg-block">
                <div className="row g-4 align-items-center me-n4">
                  <div className="col-4">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assets/images/bg/3by4/01.jpg"
                      }
                      className="rounded-3"
                      alt="3by4/01.jpg"
                    />
                  </div>

                  <div className="col-5 position-relative">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assets/images/bg/3by4/02.jpg"
                      }
                      className="rounded-3 mb-5"
                      alt="3by4/02.jpg"
                    />
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assets/images/bg/3by4/03.jpg"
                      }
                      className="rounded-3 mb-n5"
                      alt="3by4/03.jpg"
                    />

                    <div className="p-3 card card-body position-absolute top-0 start-0 translate-middle z-index-1 d-none d-xxl-block mt-6 ms-n7">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="icon-md text-bg-blue rounded-2 flex-shrink-0">
                          <svg
                            className="svg-20px"
                            xmlns="http://www.w3.org/2000/svg"
                            version="1.1"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            width={512}
                            height={512}
                            x={0}
                            y={0}
                            viewBox="0 0 49.94 49.94"
                            style={{ enableBackground: "new 0 0 512 512" }}
                            xmlSpace="preserve"
                          >
                            <g>
                              <path
                                d="M48.856 22.73a3.56 3.56 0 0 0 .906-3.671 3.56 3.56 0 0 0-2.892-2.438l-12.092-1.757a1.58 1.58 0 0 1-1.19-.865L28.182 3.043a3.56 3.56 0 0 0-3.212-1.996 3.56 3.56 0 0 0-3.211 1.996L16.352 14c-.23.467-.676.79-1.191.865L3.069 16.622A3.56 3.56 0 0 0 .177 19.06a3.56 3.56 0 0 0 .906 3.671l8.749 8.528c.373.364.544.888.456 1.4L8.224 44.701a3.506 3.506 0 0 0 .781 2.904c1.066 1.267 2.927 1.653 4.415.871l10.814-5.686a1.619 1.619 0 0 1 1.472 0l10.815 5.686a3.544 3.544 0 0 0 1.666.417c1.057 0 2.059-.47 2.748-1.288a3.505 3.505 0 0 0 .781-2.904l-2.065-12.042a1.582 1.582 0 0 1 .456-1.4l8.749-8.529z"
                                fill="#FFFFFF"
                                opacity={1}
                                data-original="#FFFFFF"
                                className="hovered-path"
                              />
                            </g>
                          </svg>
                        </div>
                        <h6 className="mb-0 small mb-0 ms-3">
                          {t("fiveStartReview")}
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assets/images/bg/3by4/04.jpg"
                      }
                      className="rounded-3 mt-7 mb-5"
                      alt="04.jpg"
                    />
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assets/images/bg/3by4/08.jpg"
                      }
                      className="rounded-3 mb-n8"
                      alt="08.jpg"
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 d-block d-lg-none ">
                <div className="all-tab-main">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/bg/3by4/all-one-tab.png"
                    }
                    alt="all-one-tab"
                    className="all-one-tab"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* discover-section End  */}
        {/* our-experienced-tutors-section Start  */}

        <ExperiencedTutorSection />

        {/* our-experienced-tutors-section End  */}
        {/* what-students-section Start  */}
        <WhatStudentSaySection />
        {/* what-students-section End  */}
        {/* planting-purpose-section Start  */}
        <section className="planting-purpose-section">
          <div className="planting-main">
            <h3 className="planting-heading">{t("plantingPurpose")}</h3>
            <h2 className="number-of-planting-heading">105,116,522</h2>
          </div>
        </section>
        {/* planting-purpose-section End  */}
        {/* sponseer-section Start  */}
        <OurSponsor />
        {/* sponseer-section End  */}
        {/* faq-section Start  */}
        <Faqs />
        {/* Blog start */}
        <Blog />
        {/* Blog End */}
      </div>

      <SelectUniversityModal
        isUniversityModalOpen={isUniversityModalOpen}
        setIsUniversityModalOpen={setIsUniversityModalOpen}
      />
    </>
  );
};

export default LandingPage;
