import React, { createContext, useContext, useEffect, useState } from "react";
import { io } from "socket.io-client";
import { useSelector } from "react-redux";

const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const { userInfo } = useSelector((state) => state.auth);

  useEffect(() => {
    if (userInfo?.id) {
      console.log("fkdjlsjfljsdlfj");
      const newSocket = io("https://api.findatutor.fr:8015/user", {
        // path: "/socket.io",
        // query: { user_id: userInfo.id },
        // transports: ["websocket"], // Use WebSocket only
        // reconnection: true, // Enable automatic reconnection
        // reconnectionDelay: 2000, // Time between reconnection attempts (in ms)
        query: { user_id: userInfo.id },
        transports: ["websocket"], // Ensures that WebSocket transport is used
        secure: true,
      });

      // console.log('newSocket: ', newSocket);
      setSocket(newSocket);
      newSocket.on('connect_error', (err) => {
        console.error('Connection error:', err);
    });
      // Cleanup on component unmount
      return () => {
        if (newSocket) {
          newSocket.disconnect();
        }
      };
    }
  }, [userInfo?.id]);

  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};

export const useSocket = () => useContext(SocketContext);
