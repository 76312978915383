import React, { Suspense } from "react";

import { Outlet } from "react-router-dom";

const loading = () => <div className=""></div>;

const AuthLayout = (props) => {
  return (
    <>
      <Suspense fallback={loading()}>
        <Outlet />
      </Suspense>
    </>
  );
};
export default AuthLayout;
