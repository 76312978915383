import moment from "moment";

export const generateTimeOptions = (startTime, isPlain = false) => {
  const times = [];
  let start;

  if (startTime) {
    start = moment(startTime, "HH:mm:ss").add(30, "minutes");
  } else {
    start = moment().startOf("day"); // 00:00
  }

  const end = moment().endOf("day"); // 24:00

  while (start.isBefore(end)) {
    times.push({
      label: start.format(isPlain ? "HH:mm" : "HH:mm a"),
      value: start.format("HH:mm:ss"),
    });
    start.add(30, "minutes");
  }

  return times;
};
