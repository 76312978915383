import React, { useCallback } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import FormikSelect from "../../../components/NiceSelect";
import { generateTimeOptions } from "../../../utils/generateTimeOptions";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { select_university } from "../../../store/slice/universitySlice";

const SelectUniversityModal = (props) => {
  const { isUniversityModalOpen, setIsUniversityModalOpen } = props;
  const { t } = useTranslation();

  const { data: universities, selected_university: selectedUniversity } =
    useSelector((state) => state.university);

  const dispatch = useDispatch();

  const ScheduleSchema = Yup.object().shape({
    university: Yup.object().required(t("pleaseSelectUniversity")),
  });

  const handleSubmit = useCallback(
    (values, action) => {
      dispatch(select_university(values?.university));
      setIsUniversityModalOpen(false);
    },
    [dispatch, setIsUniversityModalOpen]
  );

  return (
    <div className="add-std-modal">
      <div
        className={`modal ${
          isUniversityModalOpen ? "fade show d-block " : "d-none"
        }`}
        id="add-std-modal"
        tabIndex={-1}
        aria-labelledby="add-std-modalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-md  modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5>{t("selectYourUniversity")}</h5>
            </div>
            <div className="modal-body">
              <Formik
                initialValues={{
                  university: selectedUniversity?.id ? selectedUniversity : "",
                }}
                validationSchema={ScheduleSchema}
                onSubmit={handleSubmit}
              >
                {({ errors, touched, values, isSubmitting, submitCount }) => {
                  return (
                    <Form>
                      <div className="add-std-main overflow-visible">
                        <div className="add-std-one w-100">
                          <div className="add-std-input">
                            <div className="show-input">
                              <FormikSelect
                                id="a-select"
                                name="university"
                                placeholder={t("pleaseSelectYourUniversity")}
                                className="basic-single height-50 w-100"
                                disabled={!universities.length}
                                options={universities}
                                getOptionLabel={(item) => item.university_name}
                                getOptionValue={(item) => item.id}
                              ></FormikSelect>
                              {errors?.startTime?.value && submitCount > 0 && (
                                <div className="error-message">
                                  {errors?.startTime?.value}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <a
                          className="apply-to-teach-sm-anchor add-std-cancel-btn cursor-pointer"
                          onClick={() => {
                            setIsUniversityModalOpen(false);
                          }}
                        >
                          {t("cancelBtn")}
                        </a>
                        <button
                          type="submit"
                          disabled={isSubmitting}
                          className="apply-to-teach-sm-anchor"
                        >
                          {t("submitBtn")}
                        </button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
      {isUniversityModalOpen && (
        <div className="modal-backdrop fade show"></div>
      )}
    </div>
  );
};

export default SelectUniversityModal;
