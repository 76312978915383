import React, { useCallback, useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import {
  getUniversityList,
  select_university,
} from "../../store/slice/universitySlice";
import CourseModal from "./CourseModal";
import { useTranslation } from "react-i18next";
import { getCategoriesFilter } from "../../store/slice/masterSlice";
import { setlanguage } from "../../store/slice/Changelanguage";

// import "jquery-nice-select/css/style.css";

const SemesterList = ({ categoriesFilter }) => {
  console.log("categoriesFilter: ", categoriesFilter);
  const [openSemester, setOpenSemester] = useState(false); // Track the open semester
  const [openLanguage, setOpenLanguage] = useState(null); // Track the open language
  const { t } = useTranslation();

  const toggleSemester = (semesterId) => {
    // Toggle the semester visibility
    setOpenSemester((prev) => (prev === semesterId ? true : semesterId));
  };

  const toggleLanguage = (languageId) => {
    // Toggle the language visibility
    setOpenLanguage((prev) => (prev === languageId ? true : languageId));
  };

  return (
    <li className="nav-item dropdown">
      {/* Find a Tutor Dropdown Button */}
      <a
        className="nav-link active search-by-subject"
        href="javascript:void(0)"
      >
        <span data-bs-dismiss="offcanvas">
          <svg
            className="search-svg"
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width={512}
            height={512}
            viewBox="0 0 24 24"
            style={{ enableBackground: "new 0 0 512 512" }}
            xmlSpace="preserve"
          >
            <g>
              <path
                d="M21 6.75H10a.75.75 0 0 1 0-1.5h11a.75.75 0 0 1 0 1.5zm.75 5.25a.75.75 0 0 0-.75-.75H3a.75.75 0 0 0 0 1.5h18a.75.75 0 0 0 .75-.75zm-7 6a.75.75 0 0 0-.75-.75H3a.75.75 0 0 0 0 1.5h11a.75.75 0 0 0 .75-.75z"
                fill="#24292D"
              />
            </g>
          </svg>
          {/* Text */}
          {t("findATutor")}
        </span>
        {/* Dropdown Arrow */}
        <svg
          onClick={() => {
            setOpenSemester((prev) => !prev);
          }} // Toggle semester visibility
          className="svg-25"
          width={20}
          height={20}
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M16.6925 7.94217L10.4425 14.1922C10.3845 14.2503 10.3156 14.2964 10.2397 14.3278C10.1638 14.3593 10.0825 14.3755 10.0003 14.3755C9.91821 14.3755 9.83688 14.3593 9.76101 14.3278C9.68514 14.2964 9.61621 14.2503 9.55816 14.1922L3.30816 7.94217C3.19088 7.82489 3.125 7.66583 3.125 7.49998C3.125 7.33413 3.19088 7.17507 3.30816 7.05779C3.42544 6.94052 3.5845 6.87463 3.75035 6.87463C3.9162 6.87463 4.07526 6.94052 4.19253 7.05779L10.0003 12.8664L15.8082 7.05779C15.8662 6.99972 15.9352 6.95366 16.011 6.92224C16.0869 6.89081 16.1682 6.87463 16.2503 6.87463C16.3325 6.87463 16.4138 6.89081 16.4897 6.92224C16.5655 6.95366 16.6345 6.99972 16.6925 7.05779C16.7506 7.11586 16.7967 7.1848 16.8281 7.26067C16.8595 7.33654 16.8757 7.41786 16.8757 7.49998C16.8757 7.5821 16.8595 7.66342 16.8281 7.73929C16.7967 7.81516 16.7506 7.8841 16.6925 7.94217Z"
            fill="#066AC9"
          />
        </svg>
      </a>

      {/* Semester List */}
      <ul
        className="offcanvas-ul-one"
        style={{ display: openSemester ? "block" : "none" }}
      >
        {categoriesFilter?.map((semester) => (
          <li
            className="offcanvas-li-one"
            key={semester.semester + semester.id}
          >
            <Link
              to={"/find-a-tutor"}
              state={{
                find_by_semester: {
                  ...semester,
                  id: semester.semester_id,
                },
              }}
              className="dropdown-anchor"
            >
              <span data-bs-dismiss="offcanvas">{semester.semester}</span>
              {/* Right arrow icon */}
              <div className="right-side-arrow">
                <img
                  onClick={() => toggleSemester(semester.semester_id)}
                  src={`${process.env.PUBLIC_URL}/assets/images/icons/right-arrow-slider.svg`}
                  alt="right-menu-arrow"
                />
              </div>
            </Link>

            {/* Language List */}
            <ul
              className="offcanvas-ul-two"
              style={{
                display:
                  openSemester === semester.semester_id ? "block" : "none",
              }}
            >
              {semester.language_arr.map((language) => (
                <li
                  className="offcanvas-li-two"
                  key={semester.semester + language.language}
                >
                  <Link
                    to={"/find-a-tutor"}
                    state={{
                      find_by_semester: {
                        ...semester,
                        id: semester.semester_id,
                      },
                      find_by_language: {
                        ...language,
                        id: language.language_id,
                      },
                    }}
                    className="dropdown-anchor"
                  >
                    <span data-bs-dismiss="offcanvas">{language.language}</span>
                    {/* Right arrow icon */}
                    <div className="right-side-arrow">
                      <img
                        onClick={() => toggleLanguage(language.language_id)}
                        src={`${process.env.PUBLIC_URL}/assets/images/icons/right-arrow-slider.svg`}
                        alt="right-menu-arrow"
                      />
                    </div>
                  </Link>

                  {/* Subjects List */}
                  <ul
                    className="offcanvas-ul-three"
                    style={{
                      display:
                        openLanguage === language.language_id
                          ? "block"
                          : "none",
                    }}
                  >
                    {language.language_subjects.map((subject) => (
                      <li
                        className="offcanvas-li-three"
                        key={
                          semester.semester +
                          language.language +
                          subject.subject_id
                        }
                        data-bs-dismiss="offcanvas"
                      >
                        <Link
                          to={"/find-a-tutor"}
                          className="dropdown-anchor"
                          state={{
                            find_by_semester: {
                              ...semester,
                              id: semester.semester_id,
                            },
                            find_by_language: {
                              ...language,
                              id: language.language_id,
                            },
                            find_by_subjects: {
                              ...subject,
                              lang_flag:
                                language.language === "English"
                                  ? "en"
                                  : language.language === "French"
                                  ? "fr"
                                  : "de",
                              orignal_selected_subject: subject.subject_name,
                              id: subject?.subject_id,
                            },
                          }}
                        >
                          {subject.subject_name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </li>
  );
};

const LandingTopbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userInfo } = useSelector((state) => state.auth);
  const {
    filter_category: { data: categoriesFilter },
  } = useSelector((state) => state.master);

  const { data: universities, selected_university: selectedUniversity } =
    useSelector((state) => state.university);
  const changelanguage = useSelector((state) => state.changelanguage);
  const [language, setLanguage] = useState(
    changelanguage != null ? changelanguage : "EN"
  );

  const { t, i18n } = useTranslation();
  const changeLanguage = useCallback(
    (lng) => {
      i18n.changeLanguage(lng);
      setLanguage(lng);
      dispatch(setlanguage(lng));
      localStorage.setItem("selectedLanguage", lng);
    },
    [dispatch, i18n]
  );

  const [isCourse, setIsCourse] = useState(false);

  const RightSideHeader = useCallback(() => {
    return (
      <>
        {userInfo?.token ? (
          <>
            <li className="nav-item me-3">
              <button
                className="bg-transparent"
                type="button"
                id="dropdownMenuButton1"
                onClick={() =>
                  language === "EN"
                    ? changeLanguage("FR")
                    : changeLanguage("EN")
                }
              >
                <img
                  src={`${process.env.PUBLIC_URL}${
                    language === "EN"
                      ? "/assets/images/en.svg"
                      : language == "FR"
                      ? "/assets/images/FR.svg"
                      : "/assets/images/PR.svg"
                  }`}
                />{" "}
                {language}
              </button>
            </li>
            <li className="nav-item">
              <Link to="/dashboard" className="apply-to-teach-anchor">
                {t("dashboard")}
              </Link>
            </li>
          </>
        ) : (
          <>
            <li className="nav-item">
              <button
                type="button"
                className="bg-transparent"
                id="dropdownMenuButton1"
                onClick={() =>
                  language === "EN"
                    ? changeLanguage("FR")
                    : changeLanguage("EN")
                }
              >
                <img
                  src={`${process.env.PUBLIC_URL}${
                    language === "EN"
                      ? "/assets/images/en.svg"
                      : language == "FR"
                      ? "/assets/images/FR.svg"
                      : "/assets/images/PR.svg"
                  }`}
                />{" "}
                {language}
              </button>
            </li>
            <li className="nav-item">
              <Link className="nav-link login-anchor" to="/login">
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/images/icons/log-out.svg"
                  }
                  alt
                />
                {t("login")}
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link signup-anchor" to={"/student-register"}>
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/images/icons/sign-up.svg"
                  }
                  alt
                />
                {t("signUp")}
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/tutor-register" className="apply-to-teach-anchor">
                {t("applyToteach")}
              </Link>
            </li>
          </>
        )}
      </>
    );
  }, [changeLanguage, language, t, userInfo?.token]);

  useEffect(() => {
    dispatch(getUniversityList({}));
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getCategoriesFilter({
        find_by_university: selectedUniversity?.id || universities?.[0]?.id,
      })
    );
  }, [dispatch, selectedUniversity?.id, universities]);

  return (
    <>
      <Helmet>
        <script>
          {/* {` $(document).ready(function () { 
          $(".offcanvas-ul-one").css({ "display": "none" });
              $(".offcanvas-ul-two").css({ "display": "none" });
              $(".offcanvas-ul-three").css({ "display": "none" });

              $(".search-by-subject").click(function () {
                $(this).siblings().toggle({ "display": "block" });
              })

              $(".dropdown-anchor").click(function () {
                $(this).siblings().toggle({ "display": "block" });
              })

              // student-search-tutor.php Plus/Minus Js
              $(".add-remove-slots-anchor").click(function () {
                $(this).text($(this).text() == '+' ? '-' : '+');
              });
              })
          `} */}
        </script>
      </Helmet>
      <CourseModal isCourse={isCourse} setIsCourse={setIsCourse} />
      <header className="header header-without-login  login-top-fixed">
        <nav className="navbar navbar-expand-xxl">
          <div className="container">
            <Link className="navbar-brand" to="/">
              <img
                src={process.env.PUBLIC_URL + "/assets/images/logo/logo.svg"}
                alt="logo"
                className="logo"
              />
            </Link>
            <a
              className="btn tutor-offcanvas-btn border"
              data-bs-toggle="offcanvas"
              href="#offcanvasExample"
              role="button"
              aria-controls="offcanvasExample"
            >
              <span className="navbar-toggler-icon" />
            </a>
            {/* tutor-offcanvas-btn End  */}
            <div
              className="collapse navbar-collapse container"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav m-auto">
                <li
                  className="nav-item dropdown"
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate("/find-a-tutor");
                  }}
                >
                  <a
                    className="nav-link active dropdown-toggle bottom-arrow"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {t("findATutor")}
                  </a>
                  <ul className="dropdown-menu">
                    {categoriesFilter.map((semester) => (
                      <li
                        className="dropdown-li dropdown"
                        key={semester.semester + semester.id}
                      >
                        <Link
                          to={"/find-a-tutor"}
                          state={{
                            find_by_semester: {
                              ...semester,
                              id: semester.semester_id,
                            },
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          className="dropdown-anchor"
                        >
                          {semester.normal_semester}
                          <div className="right-side-arrow">
                            <img
                              src={`${process.env.PUBLIC_URL}/assets/images/icons/right-arrow-slider.svg`}
                              alt="right-menu-arrow"
                            />
                          </div>
                        </Link>
                        <ul className="dropdown-menu">
                          {semester.language_arr
                            .filter((item) =>
                              item.language_subjects.some(
                                (item) => item.subject_name
                              )
                            )
                            .map((language) => (
                              <li
                                className="dropdown-li dropdown"
                                key={semester.semester + language.language}
                              >
                                <Link
                                  to={"/find-a-tutor"}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                  state={{
                                    find_by_semester: {
                                      ...semester,
                                      id: semester.semester_id,
                                    },
                                    find_by_language: {
                                      ...language,

                                      id: language.language_id,
                                    },
                                  }}
                                  className="dropdown-anchor"
                                >
                                  {language.language}
                                  <div className="right-side-arrow">
                                    <img
                                      src={`${process.env.PUBLIC_URL}/assets/images/icons/right-arrow-slider.svg`}
                                      alt="right-menu-arrow"
                                    />
                                  </div>
                                </Link>
                                <ul className="dropdown-menu">
                                  {language.language_subjects
                                    ?.filter((item) => item.subject_name)
                                    .map((subject) => (
                                      <li
                                        className="dropdown-li dropdown"
                                        key={
                                          semester.semester +
                                          language.language +
                                          subject.subject_id
                                        }
                                      >
                                        <Link
                                          to={"/find-a-tutor"}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                          }}
                                          className="dropdown-anchor"
                                          state={{
                                            find_by_semester: {
                                              ...semester,
                                              id: semester.semester_id,
                                            },
                                            find_by_language: {
                                              ...language,
                                              id: language.language_id,
                                            },
                                            find_by_subjects: {
                                              ...subject,
                                              lang_flag:
                                                language.language === "English"
                                                  ? "en"
                                                  : language.language ===
                                                    "French"
                                                  ? "fr"
                                                  : "de",
                                              orignal_selected_subject:
                                                subject.subject_name,
                                              id: subject?.subject_id,
                                            },
                                          }}
                                        >
                                          {subject.subject_name}
                                        </Link>
                                      </li>
                                    ))}
                                </ul>
                              </li>
                            ))}
                        </ul>
                      </li>
                    ))}
                  </ul>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link cursor-pointer"
                    onClick={() => {
                      setIsCourse(true);
                    }}
                  >
                    {t("courses")}
                  </a>
                </li>
                <li className="nav-item dropdown d-flex">
                  <button
                    class="bg-transparent select-university-header dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {selectedUniversity?.university_name ||
                      t("selectYourUniversity")}
                  </button>
                  <ul class="dropdown-menu">
                    {universities?.map((item) => {
                      return (
                        <li>
                          <a
                            class={`dropdown-item cursor-pointer ${
                              selectedUniversity?.id === item.id &&
                              "selected-value-dropdown"
                            }`}
                            onClick={(e) => {
                              dispatch(select_university(item));
                            }}
                          >
                            {item.university_name}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </li>
              </ul>
              <ul className="navbar-nav mb-2 mb-lg-0">
                <RightSideHeader />
              </ul>
            </div>
            {/* tutor-offcanvas Start  */}
            <div
              className="offcanvas offcanvas-start tutor-offcanvas"
              tabIndex={-1}
              id="offcanvasExample"
              aria-labelledby="offcanvasExampleLabel"
            >
              <div className="offcanvas-header ">
                <a className="navbar-brand" href="./index.php">
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/images/logo/logo.svg"
                    }
                    alt="logo"
                    className="logo"
                  />
                </a>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                />
              </div>
              <div className="offcanvas-body">
                <ul className="navbar-nav m-auto">
                  <SemesterList categoriesFilter={categoriesFilter} />

                  <li className="nav-item">
                    <a
                      className="nav-link cursor-pointer"
                      data-bs-dismiss="offcanvas"
                      onClick={() => {
                        setIsCourse(true);
                      }}
                    >
                      {t("courses")}
                    </a>
                  </li>
                  <li className="nav-item dropdown">
                    <button
                      class="nav-link bg-transparent select-university-header dropdown-toggle select-university-drp"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {selectedUniversity?.university_name ||
                        t("selectYourUniversity")}
                    </button>
                    <ul class="dropdown-menu">
                      {universities?.map((item) => {
                        return (
                          <li>
                            <a
                              class={`dropdown-item cursor-pointer ${
                                selectedUniversity?.id === item.id &&
                                "selected-value-dropdown"
                              }`}
                              onClick={(e) => {
                                dispatch(select_university(item));
                              }}
                            >
                              {item.university_name}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                </ul>
                <ul className="navbar-nav mb-2 mb-lg-0">
                  <RightSideHeader />
                </ul>
              </div>
            </div>
            {/* tutor-offcanvas End  */}
          </div>
        </nav>
      </header>
    </>
  );
};

export default LandingTopbar;
