import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAllNotificationList, readNotification } from "../../api/apiHandler";

export const getNotificationLists = createAsyncThunk(
  "notification/getNotificationLists",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getAllNotificationList(payload);

      if (response?.code === "1") {
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const getImportantNotificationLists = createAsyncThunk(
  "notification/getImportantNotificationLists",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getAllNotificationList(payload);

      if (response?.code === "1") {
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const isReadOrDeleteNotification = createAsyncThunk(
  "notification/isReadOrDeleteNotification",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await readNotification(payload);

      if (response?.code === "1") {
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const ReadImportantNotification = createAsyncThunk(
  "notification/ReadImportantNotification",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await readNotification(payload);

      if (response?.code === "1") {
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

const initialState = {
  loading: false,
  data: [],
  error: null,
  page: 1,
  total: 0,
  unread_count: 0,
  important_notification: {
    loading: false,
    data: [],
    error: null,
    page: 1,
    total: 0,
    unread_count: 0,
  },
};

const notificationList = createSlice({
  name: "notification",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getNotificationLists.pending, (state) => {
        state.loading = true;
      })
      .addCase(getNotificationLists.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload.data.result;
        state.page = payload.data.page;
        state.total = payload.data.total_records;
        state.unread_count = payload.data.unread_count;
      })
      .addCase(getNotificationLists.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.data = [];
        state.page = 0;
        state.total = 0;
      })
      .addCase(getImportantNotificationLists.pending, (state) => {
        state.important_notification.loading = true;
      })
      .addCase(
        getImportantNotificationLists.fulfilled,
        (state, { payload }) => {
          state.important_notification.loading = false;
          state.important_notification.data = payload.data.result;
          state.important_notification.page = payload.data.page;
          state.important_notification.total = payload.data.total_records;
          state.important_notification.unread_count = payload.data.unread_count;
        }
      )
      .addCase(getImportantNotificationLists.rejected, (state, action) => {
        state.important_notification.loading = false;
        state.important_notification.error = action.error.message;
        state.important_notification.data = [];
        state.important_notification.page = 0;
        state.important_notification.total = 0;
      })
      .addCase(isReadOrDeleteNotification.pending, (state) => {
        state.loading = true;
      })
      .addCase(isReadOrDeleteNotification.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.unread_count = 0;
      })
      .addCase(isReadOrDeleteNotification.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.data = [];
        state.page = 0;
        state.total = 0;
      })
      .addCase(ReadImportantNotification.pending, (state) => {
        state.loading = true;
      })
      .addCase(ReadImportantNotification.fulfilled, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(ReadImportantNotification.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.data = [];
        state.page = 0;
        state.total = 0;
      });
  },
});

export default notificationList.reducer;
