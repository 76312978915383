import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getSubject } from "../../api/apiHandler";

export const getSubjectList = createAsyncThunk(
  "master/getSubjectList",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getSubject(payload);
      if (response?.code === "1") {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const initialState = {
  loading: false,
  data: [],
  error: null,
};

const subjectSlice = createSlice({
  name: "subject",
  initialState,

  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getSubjectList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSubjectList.fulfilled, (state, { payload }) => {
        
        state.loading = false;
        state.data = payload;
      })
      .addCase(getSubjectList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default subjectSlice.reducer;
